import React, { useEffect } from "react";
import { Label } from "../../ui/label";
import { Separator } from "../../ui/seperator";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { getUser } from "../../../utils/supabase";
import { supabase } from "../../../utils/supabase";
import { Pencil1Icon } from "@radix-ui/react-icons";

type Props = {};

interface EmailChange {
  error: boolean;
  active: boolean;
  message: string;
}

const ProfileForm = (props: Props) => {
  const [profileData, setProfileData] = React.useState({
    auth_id: "",
    email: "",
    firstName: "",
    lastName: "",
    company: "",
  });

  const [newEmail, setNewEmail] = React.useState<string>("");
  const [emailDisabled, setEmailDisabled] = React.useState<boolean>(true);
  const [emailChangeMessage, setEmailChangeMessage] =
    React.useState<EmailChange>({
      error: false,
      active: false,
      message: "",
    });

  const [successful, setSuccessful] = React.useState<boolean | null>(null);

  const updateProfile = async () => {
    const { data, error } = await supabase
      .from("customer")
      .update({
        first_name: profileData.firstName,
        last_name: profileData.lastName,
        company: profileData.company,
      })
      .eq("auth_id", profileData.auth_id);
    if (error) {
      console.log(error);
      setSuccessful(false);
    } else {
      setSuccessful(true);
    }
  };

  const updateEmail = async () => {
    const { data, error } = await supabase.auth.updateUser({
      email: newEmail,
    });
    if (error) {
      setEmailChangeMessage({
        error: true,
        active: true,
        message: "There was an issue updating your email",
      });
      return;
    }

    setEmailChangeMessage({
      error: false,
      active: true,
      message:
        "Your email has been updated. Check your email to confirm the change. Check your spam/junk folder if you don't see the email in your inbox.",
    });
    setEmailDisabled(true);
  };

  useEffect(() => {
    getUser().then((user) => {
      console.log(user);
      if (!user) return;
      setProfileData({
        email: user[0].email,
        firstName: user[0].first_name,
        lastName: user[0].last_name,
        company: user[0].company,
        auth_id: user[0].auth_id,
      });
    });
  }, []);

  useEffect(() => {
    if (successful === true) {
      setTimeout(() => {
        setSuccessful(null);
      }, 3000);
    }
  }, [successful]);

  return (
    <div className="mr-6">
      <div className="flex flex-col">
        <h3 className="font-bold">Profile</h3>
        <span className="text-sm text-jd-grey">
          Edit your profile information here{" "}
        </span>
      </div>
      <Separator className="my-4" />
      <div className="flex flex-col space-y-4">
        <Label className="text-sm">Email</Label>
        <div className="flex flex-row">
          <Input
            type="text"
            value={emailDisabled ? profileData.email : newEmail}
            disabled={emailDisabled}
            onChange={(e) => setNewEmail(e.target.value)}
            placeholder="Email"
          />
          <Pencil1Icon
            height={40}
            width={20}
            onClick={() => setEmailDisabled(!emailDisabled)}
            className="ml-2 my-auto cursor-pointer"
          />
        </div>
        <span>
          {emailChangeMessage.active && (
            <span
              className={`text-sm ${
                emailChangeMessage.error ? "text-red-500" : "text-green-500"
              }`}
            >
              {emailChangeMessage.message}
            </span>
          )}
        </span>
        {!emailDisabled && (
          <Button onClick={updateEmail} className="w-1/4 max-sm:w-full">
            Save Email
          </Button>
        )}
        <Separator />
        <Label className="text-sm">First Name</Label>
        <Input
          type="text"
          placeholder="First Name"
          value={profileData.firstName}
          onChange={(e) => {
            setProfileData({ ...profileData, firstName: e.target.value });
          }}
        />
        <Label className="text-sm">Last Name</Label>
        <Input
          type="text"
          placeholder="Last Name"
          value={profileData.lastName}
          onChange={(e) => {
            setProfileData({ ...profileData, lastName: e.target.value });
          }}
        />
        <Label className="text-sm">Company</Label>
        <Input
          type="text"
          placeholder="Company"
          value={profileData.company}
          onChange={(e) => {
            setProfileData({ ...profileData, company: e.target.value });
          }}
        />
        {successful === true && (
          <span className="text-sm text-green-500">
            Profile updated successfully
          </span>
        )}
        {successful === false && (
          <span className="text-sm text-red-500">
            Profile update failed. Please try again
          </span>
        )}
        <Button onClick={updateProfile} className="w-1/4">
          Update Profile
        </Button>
      </div>
    </div>
  );
};

export default ProfileForm;
