import React from "react";
import { DialogContent } from "../../ui/dialog";
import { getProduct } from "../../../utils/supabase";
import EmailEditor from "../EmailTemplates/EmailEditor";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { supabase } from "../../../utils/supabase";

type Props = {
  productId: number;
  setOpen: (close: boolean) => void;
};

const EmailEditorDialogue = (props: Props) => {
  const [emailContent, setEmailContent] = React.useState<any>("");
  const [emailSubject, setEmailSubject] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [productName, setProductName] = React.useState<string>("");
  const [savedSuccessfully, setSavedSuccessfully] = React.useState<
    boolean | null
  >(null);

  const updateEmailContent = async () => {
    console.log(props.productId);
    const { data, error } = await supabase
      .from("products")
      .update({
        email_content: emailContent,
        email_subject: emailSubject,
      })
      .eq("id", props.productId)
      .select();

    if (data) {
      setSavedSuccessfully(true);
      setTimeout(() => {
        setSavedSuccessfully(null);
      }, 2000);
    }
    console.log(data);
  };

  React.useEffect(() => {
    getProduct(props.productId).then((product) => {
      if (product) {
        setEmailContent(product.email_content);
        setEmailSubject(product.email_subject);
        setProductName(product.name);
      }
    });
    setLoading(false);
  }, [props.productId]);
  return (
    <DialogContent className="min-w-[80%]">
      <div className="flex flex-col px-6 py-8">
        <div className="flex flex-row justify-center">
          <Label className="text-xl">Edit {productName} Email</Label>
        </div>
        <div className="flex flex-col py-8 space-y-2 justify-center">
          <Label>Subject</Label>
          <Input
            type="text"
            onChange={(e) => setEmailSubject(e.target.value)}
            value={emailSubject}
          />
        </div>
        <Label className="pb-2">Content</Label>
        <EmailEditor initialContent={emailContent} onChange={setEmailContent} />
        {savedSuccessfully !== null && (
          <div
            className={`py-2 px-4 ${
              savedSuccessfully === true ? "bg-green-300" : "bg-red-300"
            }`}
          >
            {savedSuccessfully === true && (
              <Label className="text-green-500">Saved Successfully</Label>
            )}
            {savedSuccessfully === false && (
              <Label className="text-red-500">Error Saving</Label>
            )}
          </div>
        )}
        <Button className="mt-8" onClick={updateEmailContent}>
          Save
        </Button>
      </div>
    </DialogContent>
  );
};

export default EmailEditorDialogue;
