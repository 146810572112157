import React from "react";
import EmailEditor from "../../components/admin/EmailTemplates/EmailEditor";

type Props = {};

const EmailTemplates = (props: Props) => {
  return <div className="px-20 py-10">{/* <EmailEditor /> */}</div>;
};

export default EmailTemplates;
