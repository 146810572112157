import React, { useState } from "react";
import {
  Row,
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  FilterFn,
  SortingFn,
  sortingFns,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";

import {
  ArrowUpDown,
  ChevronDown,
  CrossIcon,
  DollarSign,
  LifeBuoy,
  MoreHorizontal,
} from "lucide-react";
import { Tabs, TabsContent, TabsTrigger, TabsList } from "../../ui/tabs";
import { Button } from "../../ui/button";
import { Checkbox } from "../../ui/checkbox";
import { Label } from "@radix-ui/react-label";
import { Oval } from "react-loader-spinner";
import { supabase, invokeFunction } from "../../../utils/supabase";
import { TrashIcon } from "lucide-react";
import { Dialog, DialogContent } from "../../ui/dialog";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../ui/dropdown";
import { Input } from "../../ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import { Cross1Icon, Cross2Icon } from "@radix-ui/react-icons";
import moment from "moment";
import {
  getOrderDetails,
  getOrders,
  sendDocumentsEmail,
} from "../../../utils/supabase";
import { Combobox } from "../../ui/combobox";

interface CustomerColumns {
  customer: string;
  first_name: string;
  last_name: string;
  document_data: any;
  email: string;
  name: string;
  order_name: string;
  status: string;
  price: number;
  paid: string;
  created_at: string;
  actions: string;
}

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  var itemRank;

  if (columnId === "first_name") {
    console.log(columnId, value);
    itemRank = rankItem(
      `${row.original.first_name} ${row.original.last_name} ${row.original.email}`,
      value
    );
  } else {
    itemRank = rankItem(row.getValue(columnId), value);
  }

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

const columns: ColumnDef<CustomerColumns>[] = [
  {
    accessorKey: "first_name",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="px-0 w-full items-center justify-start"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Customer
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    filterFn: fuzzyFilter,
    cell: ({ row }) => {
      const data = row.original;
      return (
        <>
          <p>
            {data.first_name} {data.last_name}
          </p>
          <br />
          <p>{data.email}</p>
        </>
      );
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
    enableGlobalFilter: true,
  },
  {
    accessorKey: "order_name",
    enableSorting: true,
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="px-0 w-full items-center justify-start"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Order Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return <>{row.original.document_data.smsfName}</>;
    },
    sortingFn: (rowA, rowB) => {
      const valA = rowA.original.document_data.smsfName;
      const valB = rowB.original.document_data.smsfName;
      if (valA === null || valA === undefined) return 1;
      if (valB === null || valB === undefined) return -1;
      return valA.localeCompare(valB, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    },
  },
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="px-0 w-full items-center justify-start"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Product Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    accessorKey: "status",
    enableSorting: true,
    sortingFn: "alphanumeric",
    header: "Status",
  },
  {
    accessorKey: "price",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="px-0 w-full items-center justify-start"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Price
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return <>${row.original.price?.toFixed(2)}</>;
    },
    enableHiding: false,
    enableSorting: true,
  },
  {
    accessorKey: "created_at",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="px-0 w-full items-center justify-start"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Purchase Date
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return (
        <>
          {moment(row.original.created_at).format("ddd DD MMM YYYY")} <br />
          {moment(row.original.created_at).format("hh:mm A")}
        </>
      );
    },
  },
];

interface DataTableProps<TData> {
  //   columns: ColumnDef<TData, TValue>[];
  data: TData[];
  setData: React.Dispatch<React.SetStateAction<TData[]>>;
  exportToFile?: () => void;
}

interface EmailError {
  message: string;
  error: boolean;
}

type Props = {};

const OrdersDataTable = ({
  data: originalData,
  exportToFile,
}: DataTableProps<any>) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = useState("");

  const [additionalInfo, setAdditionalInfo] = React.useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<EmailError>({
    error: false,
    message: "",
  });
  const [selectDocumentError, setSelectDocumentError] =
    useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean | null>(null);
  const [sendDocument, setSendDocument] = useState<boolean>(false);
  const [sendInvoice, setSendInvoice] = useState<boolean>(false);
  const [sendInstructions, setSendInstructions] = useState<boolean>(false);
  const [editsAllowed, setEditsAllowed] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [data, setData] = useState(originalData);

  // Function to filter data based on selected product
  const filterDataByProduct = (productName: string | null) => {
    if (!productName || productName.length < 1) {
      setData(originalData);
    } else {
      setData(
        originalData.filter((item) => item.name.toLowerCase() === productName)
      );
    }
  };

  const filterDataByStatus = (statusName: string | null) => {
    if (!statusName || statusName.length < 1) {
      setData(originalData);
    } else {
      setData(
        originalData.filter((item) => item.status.toLowerCase() === statusName)
      );
    }
  };

  React.useEffect(() => {
    setData(originalData);
  }, [originalData]);

  React.useEffect(() => {
    filterDataByProduct(selectedProduct);
  }, [selectedProduct]);

  React.useEffect(() => {
    filterDataByStatus(selectedStatus);
  }, [selectedStatus]);

  const sendEmail = async (orderId: string, email: string) => {
    if (email.length < 5 || !email.includes("@") || !email.includes(".")) {
      setEmailError({
        error: true,
        message: "Please enter a valid email",
      });
      return;
    }
    if (sendDocument === false && sendInstructions === false) {
      setSelectDocumentError(true);
      return;
    }
    setEmailSent(false);
    const { data, error } = await supabase
      .from("document_orders")
      .update({
        status: "Paid",
      })
      .eq("id", orderId)
      .select();
    console.log(data, error);
    const dor = data && data[0];
    if (data && data.length === 1) {
      try {
        const d = await sendDocumentsEmail({
          orderId: dor.id,
          email: email,
          invoice: sendInvoice,
          instructions: sendInstructions,
          document: sendDocument,
        });
        if (d.status === 546) {
          setEmailSent(false);
          setEmailError({
            error: true,
            message: d.message,
          });
          return;
        }

        setEmailSent(true);
        setEmailError({
          error: false,
          message: d.message,
        });
      } catch (e) {
        console.log(e);
        setEmailSent(false);
        setEmailError({
          error: true,
          message: "Something went wrong, please try again in a couple minutes",
        });
      }
    }
  };

  React.useEffect(() => {
    if (emailSent === true) {
      setTimeout(() => {
        setEmailSent(null);
      }, 3000);
    } else if (emailSent === false) {
      setTimeout(() => {
        setEmailSent(null);
      }, 5000);
    }
  }, [emailSent]);

  const downloadDocument = (documentUrl: string) => {
    const documentLink = document.createElement("a");
    documentLink.href = documentUrl;
    documentLink.target = "_blank";
    document.body.appendChild(documentLink);
    documentLink.click();
  };

  const editableChangehandler = async (orderId: string, e: any) => {
    // setOrders(
    //   orders.map((o) => {
    //     if (o.id === orderId) {
    //       o.order_editable = e === "Editable" ? true : false;
    //     }
    //     return o;
    //   })
    // );

    const { data, error } = await supabase
      .from("document_orders")
      .update({
        order_editable: e === "Editable" ? true : false,
      })
      .eq("id", orderId)
      .select();

    if (error) {
      console.log(error);
      alert("There was an issue updating the order. Try again");
    }
  };

  const deleteOrder = async (orderId: string) => {
    const { data, error } = await supabase
      .from("document_orders")
      .delete()
      .eq("id", orderId)
      .select();
    if (error) {
      console.log(error);
      alert("There was an issue deleting the order. Try again");
    }
  };

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter,
    },
  });

  const uniqueProducts = Array.from(
    new Set(originalData.map((item) => item.name))
  ).sort();
  const uniqueStats = Array.from(
    new Set(originalData.map((item) => item.status))
  ).sort();

  return (
    <div className="w-full">
      <div className="flex items-center gap-x-2 py-4">
        <Input
          placeholder="Search"
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(value.target.value)}
          className="max-w-sm"
        />
        <Combobox
          label="Product"
          value={selectedProduct}
          setValue={setSelectedProduct}
          items={uniqueProducts.map((product) => ({
            label: product,
            value: product.toLowerCase(),
          }))}
        />
        <Combobox
          label="Status"
          value={selectedStatus}
          setValue={setSelectedStatus}
          items={uniqueStats.map((status) => ({
            label: status,
            value: status.toLowerCase(),
          }))}
        />
        {/* <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Columns <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu> */}
        <Button onClick={exportToFile}>Download</Button>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <>
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                    <TableCell>
                      <div className="flex flex-row align-middle gap-x-2 justify-end">
                        <Button
                          onClick={() => {
                            if (additionalInfo === row.original.id) {
                              setAdditionalInfo("");
                            } else {
                              setAdditionalInfo(row.original.id);
                            }
                          }}
                          variant="default"
                        >
                          More Info
                        </Button>

                        {row.original.status !== "Paid" && (
                          <>
                            <Button
                              variant="destructive"
                              onClick={() => setDeleteOpen(true)}
                            >
                              <TrashIcon />
                            </Button>
                            <Dialog
                              open={deleteOpen}
                              onOpenChange={() => setDeleteOpen(!deleteOpen)}
                            >
                              <DeleteDialog
                                orderId={row.original.id}
                                setOpen={setDeleteOpen}
                              />
                            </Dialog>
                          </>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                  {additionalInfo === row.original.id && (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <div className="flex flex-row bg-slate-200  w-full gap-x-4 py-4 px-6 rounded-md">
                          <div className="flex flex-col w-1/4 justify-around space-y-6">
                            <Button
                              className="bg-orange-500"
                              onClick={() =>
                                downloadDocument(row.original.file_url)
                              }
                            >
                              Download Document
                            </Button>
                            <Button
                              className="bg-orange-500"
                              onClick={() =>
                                downloadDocument(row.original.instructions_url)
                              }
                            >
                              Download Instructions
                            </Button>
                            <Button
                              className="bg-orange-500"
                              onClick={() =>
                                downloadDocument(row.original.invoice_url)
                              }
                            >
                              Download Invoice
                            </Button>
                          </div>
                          <div className="flex flex-col w-1/4  space-y-4">
                            <Label className="pb-2">
                              Select documents to include in email
                            </Label>
                            {selectDocumentError === true ? (
                              <div className="bg-red-300 max-w-fit px-4 py-1 rounded-md">
                                <span className="text-red-800">
                                  Please select one document to send
                                </span>
                              </div>
                            ) : null}
                            <div className="flex flex-row items-center">
                              <Checkbox
                                checked={sendDocument}
                                onClick={() => {
                                  setSendDocument(!sendDocument);
                                  setSelectDocumentError(false);
                                }}
                                className="bg-white"
                                id="terms"
                              />
                              <Label className="pl-2">
                                {row.original.name}
                              </Label>
                            </div>
                            <div className="flex flex-row items-center">
                              <Checkbox
                                id="terms"
                                checked={sendInvoice}
                                onClick={() => setSendInvoice(!sendInvoice)}
                              />
                              <Label className="pl-2">Invoice</Label>
                            </div>
                            <div className="flex flex-row items-center">
                              <Checkbox
                                id="terms"
                                checked={sendInstructions}
                                onClick={() => {
                                  setSendInstructions(!sendInstructions);
                                  setSelectDocumentError(false);
                                }}
                              />
                              <Label className="pl-2">
                                Document Instructions
                              </Label>
                            </div>
                          </div>
                          <div className="flex flex-col space-y-4 w-1/4">
                            <div className="flex flex-col gap-y-4 items-center">
                              <div className="flex flex-col w-full justify-around space-x-6"></div>
                              <Input
                                placeholder="Email"
                                type="email"
                                disabled={emailSent !== null}
                                required
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                  setEmailError({
                                    ...emailError,
                                    error: false,
                                  });
                                }}
                              ></Input>
                              {emailSent !== null &&
                              emailError.message.length > 1 ? (
                                <div
                                  className={`${
                                    emailError.message.includes("sent")
                                      ? "bg-green-800"
                                      : "bg-red-800"
                                  } w-full px-4 py-1 rounded-md text-center`}
                                >
                                  <span
                                    className={`${
                                      emailError.message.includes("sent")
                                        ? "text-green-300"
                                        : "text-red-300"
                                    }`}
                                  >
                                    {emailError.message}
                                  </span>
                                </div>
                              ) : (
                                <div className="-my-3"></div>
                              )}
                              <Button
                                onClick={() =>
                                  sendEmail(row.original.id, email)
                                }
                                className="w-full "
                                disabled={emailSent !== null}
                              >
                                {emailSent === null ? (
                                  "Email"
                                ) : (
                                  <Oval width="20" height="20" />
                                )}
                              </Button>
                            </div>
                          </div>
                          <div className="flex flex-col space-y-4 w-1/4">
                            <div className="flex flex-col gap-y-4 items-center">
                              <div className="flex flex-col w-full justify-around space-x-6"></div>
                              <Tabs
                                defaultValue={
                                  row.original.order_editable === true
                                    ? "Editable"
                                    : "NonEditable"
                                }
                                onValueChange={(e) => {
                                  editableChangehandler(row.original.id, e);
                                }}
                                className="w-[400px]"
                              >
                                <TabsList className="grid w-full grid-cols-2">
                                  <TabsTrigger value="Editable">
                                    Edits Allowed
                                  </TabsTrigger>
                                  <TabsTrigger value="NonEditable" className="">
                                    Edits Disallowed
                                  </TabsTrigger>
                                </TabsList>
                              </Tabs>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        {/* <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div> */}
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

interface DeleteDialogTypes {
  orderId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteDialog = ({ orderId, setOpen }: DeleteDialogTypes) => {
  const deleteOrder = async () => {
    const { data, error } = await supabase
      .from("document_orders")
      .delete()
      .eq("id", orderId);

    if (error) {
      console.log(error);
      return;
    }

    window.location.reload();

    setOpen(false);
  };
  return (
    <DialogContent>
      <div className="flex flex-col w-full gap-y-6 px-4 py-6">
        <span>
          Are you sure you want to delete this order? This action can't be
          undone
        </span>
        <Button variant="destructive" onClick={() => deleteOrder()}>
          Delete
        </Button>
        <Button variant="secondary" onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </div>
    </DialogContent>
  );
};

export default OrdersDataTable;
