import React, { useEffect } from "react";
import PageContainer from "../../../containers/PageContainer";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

import { Tabs, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import { Button } from "../../../components/ui/button";

import { getCustomPricingActive, getProduct } from "../../../utils/supabase";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOrder } from "../../../context/Order.context";
import { supabase } from "../../../utils/supabase";
import { useUser } from "../../../context/User.context";
import { Separator } from "../../../components/ui/seperator";

type Props = {};

interface TrustDeedForm {
  smsfName: string;
  address: string;
  suburb: string;
  state: string;
  postcode: number;
  isTrusteeCompany: boolean;
  trusteeCompanyName?: string;
  trusteeCompanyACN?: string;
  trustees: {
    firstName: string;
    middleName: string;
    lastName: string;
  }[];
  chairPerson: string;
}

const NewTrustDeed = (props: Props) => {
  const [company, setCompany] = React.useState(false);
  const [trustees, setTrustees] = React.useState(2);
  const [smsfNameTemp, setSmsfNameTemp] = React.useState("");
  const [suffixTemp, setSuffixTemp] = React.useState("");
  const [form, setForm] = React.useState<TrustDeedForm>({
    smsfName: "",
    address: "",
    suburb: "",
    state: "",
    postcode: 2000,
    isTrusteeCompany: false,
    trusteeCompanyName: "",
    trusteeCompanyACN: "",
    trustees: [
      {
        firstName: " ",
        middleName: " ",
        lastName: " ",
      },
      {
        firstName: " ",
        middleName: " ",
        lastName: " ",
      },
    ],
    chairPerson: "",
  });
  const [loading, setLoading] = React.useState(true);

  const navigate = useNavigate();
  const { setOrder } = useOrder();
  const { auth_id } = useUser();

  const handleForm = async (event: any) => {
    event.preventDefault();
    const { data, error } = await supabase
      .from("document_orders")
      .insert([
        {
          auth_id: auth_id,
          document_data: form,
          product_id: 2,
          status: "pending",
        },
      ])
      .select();
    if (data) {
      const customPricing = await getCustomPricingActive(auth_id, 2);

      const customPrice = customPricing ? customPricing.price : null;

      console.log(customPricing);
      localStorage.setItem("order_number", data[0].id);
      const product = await getProduct(2);
      setOrder({
        form: form,
        product: product.name,
        price: customPrice ? customPrice : product.price,
        description: product.description,
        order_number: data[0].id,
      });
      navigate("/customer/payment");
    }
  };

  // Get URL parameter order_id
  const [searchParams, setSearchParams] = useSearchParams();

  const saveOrder = async (e: any) => {
    e.preventDefault();
    if (searchParams.get("order_id")) {
      const { data, error } = await supabase
        .from("document_orders")
        .upsert(
          [
            {
              id: searchParams.get("order_id"),
              auth_id: auth_id,
              document_data: form,
              product_id: 2,
              status: "pending",
            },
          ],
          { onConflict: "id" }
        )
        .select();
    } else {
      const { data, error } = await supabase
        .from("document_orders")
        .insert([
          {
            auth_id: auth_id,
            document_data: form,
            product_id: 2,
            status: "pending",
          },
        ])
        .select();
    }

    navigate("/customer/orders");
  };

  useEffect(() => {
    const order_id = searchParams.get("order_id");
    if (order_id) {
      const getOrder = async () => {
        const { data, error } = await supabase
          .from("document_orders")
          .select()
          .eq("id", order_id);
        if (data) {
          console.log(data[0].document_data);
          setForm(data[0].document_data);
          if (data[0].document_data.smsfName.includes("PTY")) {
            setSmsfNameTemp(data[0].document_data.smsfName.split(" PTY")[0]);
            setSuffixTemp(
              `PTY${data[0].document_data.smsfName.split(" PTY")[1]}`
            );
          } else {
            setSmsfNameTemp(data[0].document_data.smsfName.split(" PR")[0]);
            setSuffixTemp(
              `PR${data[0].document_data.smsfName.split(" PR")[1]}`
            );
          }
        }
      };
      getOrder().then((r) => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <PageContainer pageTitle="SMSF Deed Update">
      {loading === false && (
        <form onSubmit={(e) => handleForm(e)}>
          <div className="flex flex-col gap-y-8 pt-4">
            <div className="flex flex-col pt-6 gap-y-2">
              <Label>
                Name of SMSF<span className="text-red-500">*</span>
              </Label>
              <div className="flex flex-row gap-x-4">
                <div className="w-1/2 max-sm:w-3/4">
                  <Input
                    type="text"
                    required
                    value={smsfNameTemp}
                    placeholder="SMSF Name"
                    onChange={(e) => {
                      setSmsfNameTemp(e.target.value);
                      setForm({
                        ...form,
                        smsfName: `${e.target.value} ${suffixTemp}`,
                      });
                    }}
                  />
                </div>
                <div className="w-1/4">
                  <Select
                    required
                    value={suffixTemp}
                    onValueChange={(e) => {
                      setSuffixTemp(e);
                      setForm({ ...form, smsfName: `${smsfNameTemp} ${e}` });
                    }}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Suffix" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="PTY LTD">PTY LTD</SelectItem>
                        <SelectItem value="PTY. LTD.">PTY. LTD.</SelectItem>
                        <SelectItem value="PTY. LIMITED">
                          PTY. LIMITED
                        </SelectItem>
                        <SelectItem value="PTY LIMITED">PTY LIMITED</SelectItem>
                        <SelectItem value="PROPRIETARY LTD">
                          PROPRIETARY LTD
                        </SelectItem>
                        <SelectItem value="PROPRIETARY LIMITED">
                          PROPRIETARY LIMITED
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-2">
              <Label>
                Street Number and Name<span className="text-red-500">*</span>
              </Label>
              <Input
                type="text"
                required
                value={form.address}
                placeholder="42 Wallaby Way"
                onChange={(e) => {
                  setForm({ ...form, address: e.target.value });
                }}
              />
            </div>
            <div className="flex max-sm:flex-col flex-row gap-y-2 gap-x-4">
              <div className="flex flex-col gap-y-2 max-sm:w-full w-6/12">
                <Label>
                  Suburn/Town<span className="text-red-500">*</span>
                </Label>
                <Input
                  type="text"
                  value={form.suburb}
                  required
                  onChange={(e) => setForm({ ...form, suburb: e.target.value })}
                  placeholder="Randwick"
                />
              </div>
              <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                <Label>
                  State<span className="text-red-500">*</span>
                </Label>
                <Select
                  required
                  value={form.state}
                  defaultValue={form.state ? form.state : "NSW"}
                  onValueChange={(e) => setForm({ ...form, state: e })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a state" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="NSW">NSW</SelectItem>
                      <SelectItem value="VIC">VIC</SelectItem>
                      <SelectItem value="QLD">QLD</SelectItem>
                      <SelectItem value="ACT">ACT</SelectItem>
                      <SelectItem value="SA">SA</SelectItem>
                      <SelectItem value="WA">WA</SelectItem>
                      <SelectItem value="NT">NT</SelectItem>
                      <SelectItem value="TAS">TAS</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex flex-col gap-y-2  max-sm:w-full w-3/12">
                <Label>
                  Postcode<span className="text-red-500">*</span>
                </Label>
                <Input
                  type="number"
                  value={form.postcode}
                  required
                  onChange={(e) =>
                    setForm({ ...form, postcode: parseInt(e.target.value) })
                  }
                  placeholder="2000"
                />
              </div>
            </div>
            <div className="flex flex-row gap-y-2 gap-x-4 pt-6">
              <div className="flex flex-col w-1/4 max-sm:w-full gap-y-6">
                <Label>Is the trustee of the SMSF a company?</Label>
                <Tabs
                  defaultValue={form.isTrusteeCompany ? "yes" : "no"}
                  value={form.isTrusteeCompany ? "yes" : "no"}
                  onValueChange={(e) => {
                    setCompany(e === "yes" ? true : false);
                    setForm({
                      ...form,
                      isTrusteeCompany: e === "yes" ? true : false,
                    });
                  }}
                  className="w-[200px]  max-sm:w-full"
                >
                  <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="no">No</TabsTrigger>
                    <TabsTrigger value="yes">Yes</TabsTrigger>
                  </TabsList>
                </Tabs>
              </div>
            </div>
            {company && (
              <div className="flex max-sm:flex-col flex-row gap-y-2 gap-x-4 pt-6">
                <div className="flex flex-col w-1/2 max-sm:w-full gap-y-2">
                  <Label>
                    Name of Trustee Company
                    <span className="text-red-500">*</span>
                  </Label>

                  <div className="flex flex-row gap-x-2">
                    <Input
                      value={smsfNameTemp}
                      type="text"
                      required
                      placeholder="Company Name"
                      onChange={(e) => {
                        setSmsfNameTemp(e.target.value);
                        setForm({
                          ...form,
                          trusteeCompanyName: `${e.target.value} ${suffixTemp}`,
                        });
                      }}
                    />
                    <Select
                      required
                      value={suffixTemp}
                      onValueChange={(e) => {
                        setSuffixTemp(e);
                        setForm({
                          ...form,
                          trusteeCompanyName: `${smsfNameTemp} ${e}`,
                        });
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Suffix" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="PTY LTD">PTY LTD</SelectItem>
                          <SelectItem value="PTY. LTD.">PTY. LTD.</SelectItem>
                          <SelectItem value="PTY. LIMITED">
                            PTY. LIMITED
                          </SelectItem>
                          <SelectItem value="PTY LIMITED">
                            PTY LIMITED
                          </SelectItem>
                          <SelectItem value="PROPRIETARY LTD">
                            PROPRIETARY LTD
                          </SelectItem>
                          <SelectItem value="PROPRIETARY LIMITED">
                            PROPRIETARY LIMITED
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="flex flex-col w-1/2 max-sm:w-full gap-y-2">
                  <Label>
                    ACN of Trustee Company
                    <span className="text-red-500">*</span>
                  </Label>
                  <Input
                    value={form.trusteeCompanyACN}
                    type="text"
                    required
                    placeholder="365 455 652"
                    onChange={(e) => {
                      setForm({ ...form, trusteeCompanyACN: e.target.value });
                    }}
                  />
                </div>
              </div>
            )}

            <div className="flex flex-row gap-y-2 gap-x-4 pt-6">
              <div className="flex flex-col w-1/2 max-sm:w-full gap-y-2">
                <h4 className="text-bold text-2xl text-jd-grey">
                  Member/Trustee Details
                </h4>
                <span className="text-jd-blue font-semibold">
                  Please enter the details of all members/trustees of the SMSF
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-y-2 gap-x-4">
              <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                <Label>How many members/trustees are there?</Label>
                <Select
                  required
                  value={trustees.toString()}
                  defaultValue={form.isTrusteeCompany ? "1" : "2"}
                  onValueChange={(e) => {
                    setTrustees(parseInt(e));
                    setForm({
                      ...form,
                      trustees: Array(parseInt(e))
                        .fill({
                          firstName: "",
                          middleName: "",
                          lastName: "",
                        })
                        .map((trustee, index) => {
                          if (form.trustees[index]) {
                            return form.trustees[index];
                          }
                          return trustee;
                        }),
                    });
                  }}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {form.isTrusteeCompany ? (
                        <SelectItem value="1">1</SelectItem>
                      ) : (
                        <></>
                      )}
                      <SelectItem value="2">2</SelectItem>
                      <SelectItem value="3">3</SelectItem>
                      <SelectItem value="4">4</SelectItem>
                      <SelectItem value="5">5</SelectItem>

                      <SelectItem value="6">6</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            {Array.from(Array(trustees).keys()).map((i) => (
              <>
                <div
                  key={`trustee-${i}`}
                  className="flex flex-row max-sm:flex-col gap-y-2 gap-x-4 pt-4"
                >
                  <Label className="max-sm:block hidden">Member {i + 1}</Label>
                  <Separator className="h-[2px] border-jd-blue hidden max-sm:block" />
                  <div className="flex flex-col gap-y-2  max-sm:w-full w-4/12">
                    <Label>
                      First Name<span className="text-red-500">*</span>
                    </Label>
                    <Input
                      type="text"
                      required
                      value={form.trustees[i].firstName}
                      placeholder="First Name"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          trustees: form.trustees.map((trustee, index) => {
                            if (index === i) {
                              return {
                                ...trustee,
                                firstName: e.target.value,
                              };
                            }
                            return trustee;
                          }),
                        })
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 max-sm:w-full w-4/12">
                    <Label>Middle Name</Label>
                    <Input
                      type="text"
                      value={form.trustees[i].middleName}
                      placeholder="Middle Name"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          trustees: form.trustees.map((trustee, index) => {
                            if (index === i) {
                              return {
                                ...trustee,
                                middleName: e.target.value,
                              };
                            }
                            return trustee;
                          }),
                        })
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 max-sm:w-full w-4/12">
                    <Label>
                      Last Name<span className="text-red-500">*</span>
                    </Label>
                    <Input
                      type="text"
                      value={form.trustees[i].lastName}
                      required
                      placeholder="Last Name"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          trustees: form.trustees.map((trustee, index) => {
                            if (index === i) {
                              return {
                                ...trustee,
                                lastName: e.target.value,
                              };
                            }
                            return trustee;
                          }),
                        })
                      }
                    />
                  </div>
                </div>
              </>
            ))}
            <div className="flex flex-col gap-y-2  max-sm:w-full w-3/12">
              <Label>
                Chair Person<span className="text-red-500">*</span>
              </Label>
              <Select
                value={form.chairPerson}
                defaultValue={form.chairPerson}
                required
                onValueChange={(e) => setForm({ ...form, chairPerson: e })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a chairperson" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {form.trustees
                      .filter(
                        (e) =>
                          `${e.firstName}${e.middleName}${e.lastName}`.length >
                          4
                      )
                      .map((trustee, index) => {
                        return (
                          <SelectItem
                            key={`trustee-${index}`}
                            value={`${trustee.firstName} ${trustee.middleName} ${trustee.lastName}`}
                          >
                            {trustee.firstName} {trustee.middleName}{" "}
                            {trustee.lastName}
                          </SelectItem>
                        );
                      })}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex flex-row max-sm:flex-col-reverse max-sm:gap-x-4 justify-between gap-y-2 gap-x-4 pt-6">
              <div className="flex flex-col gap-y-2 max-sm:w-full  w-2/12">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/customer/orders");
                  }}
                  variant="secondary"
                >
                  Go Back
                </Button>
              </div>
              <div className="flex flex-row max-sm:flex-col gap-x-2 gap-y-2 max-sm:w-full w-2/12">
                <Button onClick={(e) => saveOrder(e)} variant="default">
                  Save
                </Button>
                <Button type="submit" variant="default">
                  Save & Continue
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </PageContainer>
  );
};

export default NewTrustDeed;
