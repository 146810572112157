import React from "react";
import PageContainer from "../../containers/PageContainer";

type Props = {};

const PrivacyPolicy = (props: Props) => {
  return (
    <PageContainer pageTitle="Privacy Policy for JustDocs">
      <div className=" flex flex-col gap-y-6 pt-8">
        <p>Last Updated: 01/01/2024</p>
        <p>
          Welcome to JustDocs! This Privacy Policy explains how we collect, use,
          disclose, and safeguard your personal information when you use our
          services. By accessing or using JustDocs, you agree to the terms of
          this Privacy Policy.
        </p>
        <div className="flex flex-col gap-y-2">
          <span className="font-bold">1. Information We Collect</span>
          <span>
            a. <span className="font-bold">Personal Information</span>
          </span>
          <ul className="list-disc ml-4 pl-4">
            <li>Name</li>
            <li>Contact Information (email address, phone number)</li>
            <li>Billing Information</li>
            <li>User Account Information</li>
          </ul>
          <span>
            b. <span className="font-bold">Usage Information</span>
          </span>
          <ul className="list-disc ml-4 pl-4">
            <li>IP Address</li>
            <li>Device Information</li>
            <li>Browser Type</li>
            <li>Pages Visited</li>
          </ul>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="font-bold">2. How We Collect Information</span>
          <span>
            a. <span className="font-bold">Directly from You</span>
          </span>
          <ul className="list-disc ml-4 pl-4">
            <li>When you create an account</li>
            <li>When you make a purchase</li>
            <li>When you contact customer support</li>
          </ul>
          <span>
            b. <span className="font-bold">Automatically</span>
          </span>
          <ul className="list-disc ml-4 pl-4">
            <li>Through cookies and similar technologies</li>
            <li>Through server logs and analytics tools</li>
          </ul>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="font-bold">3. How We Use Your Information</span>
          <span>
            a. <span className="font-bold">Providing Services</span>
          </span>
          <ul className="list-disc ml-4 pl-4">
            <li>To fulfill your requests and orders</li>
            <li>To provide customer support</li>
          </ul>
          <span>
            b. <span className="font-bold">Communication</span>
          </span>
          <ul className="list-disc ml-4 pl-4">
            <li>To send you updates, newsletters, and promotional materials</li>
            <li>To respond to your inquiries</li>
          </ul>
          <span>
            c. <span className="font-bold">Improving Our Services</span>
          </span>
          <ul className="list-disc ml-4 pl-4">
            <li>To analyze and enhance the user experience</li>
            <li>To develop new features and services</li>
          </ul>
          <span>
            d. <span className="font-bold">Legal Compliance</span>
          </span>
          <ul className="list-disc ml-4 pl-4">
            <li>To comply with legal obligations</li>
            <li>To protect our rights and interests</li>
          </ul>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="font-bold">4. Sharing Your Information</span>
          <span>
            a. <span className="font-bold">Third-Party Service Providers</span>
          </span>
          <ul className="list-disc ml-4 pl-4">
            <li>
              We may share information with trusted third-party service
              providers for business-related purposes (e.g., payment processing,
              analytics).
            </li>
          </ul>
          <span>
            b. <span className="font-bold">Legal Requirements</span>
          </span>
          <ul className="list-disc ml-4 pl-4">
            <li>
              We may disclose information when required by law or in response to
              legal requests.
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="font-bold">5. Your Choices</span>
          <span>
            a. <span className="font-bold">Account Information</span>
          </span>
          <ul className="list-disc ml-4 pl-4">
            <li>
              You can review and update your account information by logging into
              your account.
            </li>
          </ul>
          <span>
            b. <span className="font-bold">Marketing Communications</span>
          </span>
          <ul className="list-disc ml-4 pl-4">
            <li>
              You can opt out of receiving marketing communications by following
              the instructions in the communication.
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="font-bold">6. Security</span>
          <span>
            a. We take reasonable measures to protect your personal information
            from unauthorized access, use, or disclosure.
          </span>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="font-bold">7. Cookies</span>
          <span>
            a. We use cookies and similar technologies to enhance your
            experience and for analytics purposes. You can manage your cookie
            preferences through your browser settings.
          </span>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="font-bold">8. Changes to this Privacy Policy</span>
          <span>
            a. We may update this Privacy Policy from time to time. The date of
            the latest revision will be indicated at the top of the policy.
          </span>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="font-bold">9. Contact Us</span>
          <span>
            If you have any questions or concerns about our Privacy Policy,
            please contact us at info@justdocs.com.au
          </span>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="font-bold">10. Governing Law</span>
          <span>This Privacy Policy is governed by the laws of Australia.</span>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="font-bold">Thank for choosing Just Docs!</span>
        </div>
      </div>
    </PageContainer>
  );
};

export default PrivacyPolicy;
