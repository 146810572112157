import React, { useEffect, useState } from "react";
import PageContainer from "../../../../containers/PageContainer";
import { Button } from "../../../../components/ui/button";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../../context/User.context";
import { getCustomPricingActive, getProduct } from "../../../../utils/supabase";
import Footer from "../../../../components/public/footer";

type Props = {};

const TrustDeedPreScreen = (props: Props) => {
  const navigate = useNavigate();
  const { auth_id } = useUser();
  const [price, setPrice] = useState<number>(0);

  useEffect(() => {
    if (auth_id) {
      console.log("auth_id", auth_id);
      getCustomPricingActive(auth_id, 2)
        .then((data: any) => {
          if (data) {
            setPrice(data.price);
            console.log(data);
          }
          if (!data) {
            getProduct(2).then((data) => {
              if (data) {
                setPrice(data.price);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getProduct(2).then((data) => {
        if (data) {
          setPrice(data.price);
        }
      });
    }
  }, []);

  return (
    <PageContainer pageTitle="SMSF Deed Update">
      <div className="flex flex-row max-sm:flex-col-reverse pt-10 pb-10">
        <div className="flex flex-col w-3/4 gap-y-4">
          <h4 className="font-bold">About this product</h4>
          <p>
            This product updates the SMSF deed to reflect current superannuation
            legislation.
          </p>
          <br />
          <h4 className=" font-bold">What's included</h4>
          <p>A single PDF with the following:</p>

          <ul className="list-disc pl-4">
            <li>Deed of Variation</li>
            <li>Updated Trust Deed.</li>
            <li>Trustee minutes to adapt the variation & notice to members.</li>
          </ul>
          <br />
          <h4 className=" font-bold">Important</h4>
          <p>
            This product cannot be used to update the trustees or members of an
            SMSF.
          </p>
          <br />
          <h4 className=" font-bold">Why update your SMSF deed?</h4>
          <p>
            Regular legislative changes in law, necessitate that SMSF deeds be
            reviewed and updated at regular intervals. Having an outdated deed
            with invalid clauses not only poses a danger of not complying with
            current legislation, it could also deprive you of advantages of new
            strategies that have been developed in view of these latest changes.
            <br />
            <br />
            The ATO states &quot;As a trustee, you need to make sure the trust
            deed is regularly reviewed and updated so it complies with the super
            laws (including changes to the law) and the members&#39;
            needs.&quot; Some of the most recent changes include the following:
            <br />
            <br />
            <ol className=" list-decimal pl-10">
              <li>
                Automatic appointment of LPR of a member in case of unsound
                mind;
              </li>
              <li> Fund can accept contributions from a full time employee;</li>
              <li>
                Acceptance of additional concessional contributions above the
                cap amount in certain circumstances;
              </li>
              <li>
                Roll out payment of a death benefit can either be in cash or
                in-specie;
              </li>
              <li>
                Calculation of Exempt current pension income as per the ATO’s
                new view;
              </li>
              <li>
                Expanded allowable investments to digital assets &amp; crypto
                currencies;
              </li>
              <li>
                Adding/replacing/removing a reversionary beneficiary without
                commuting a pension;
              </li>
              <li>
                Acceptance of a death benefit payment as roll over from another
                fund;
              </li>
              <li>
                Accept voluntary contributions from members under the First Home
                super saver scheme;
              </li>
              <li>
                Automatically converting a TRIS to an account based pension in
                case of meeting nil conditions of release;
              </li>
              <li>
                Changes to bring forward rules where non-concessional
                contributions are restricted by total superannuation balance of
                the member.
              </li>
              <li>Deed allowing 6-member funds.</li>
              <li>
                Indexation of balance transfer cap and adjustment to cap amounts
                of concessional and non-concessional contributions which can be
                made to the fund.
              </li>
              <li>
                Deed updated so trustees can receive payments in accordance with
                &#39;Non-Arm&#39;s Length Expenses&#39; (NALE) rules
              </li>
              <li>
                Deed updated to clarify provisions in relation to total and
                permanent disablement and retirement provisions.
              </li>
              <li>
                Removal of provisions on forfeiture of beneﬁt entitlements.
              </li>
            </ol>
          </p>
        </div>
        <div className="flex flex-col max-sm:pb-4 max-sm:w-full w-1/4">
          <div className="flex flex-col space-y-6 py-4 w-full border-2 px-4 rounded-lg">
            <span className="font-bold text-lg">${price} inc GST</span>
            <Button onClick={() => navigate("/customer/docs/new-trust-deed")}>
              Begin Order
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </PageContainer>
  );
};

export default TrustDeedPreScreen;
