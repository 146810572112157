import React, { useEffect, useState } from "react";
import PageContainer from "../../../../containers/PageContainer";
import { Button } from "../../../../components/ui/button";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../../context/User.context";
import { getCustomPricingActive, getProduct } from "../../../../utils/supabase";
import Footer from "../../../../components/public/footer";
type Props = {};

const DeathBenefitPreScreen = (props: Props) => {
  const navigate = useNavigate();
  const { auth_id } = useUser();
  const [price, setPrice] = useState<number>(0);

  useEffect(() => {
    if (auth_id) {
      console.log("auth_id", auth_id);
      getCustomPricingActive(auth_id, 1)
        .then((data: any) => {
          if (data) {
            setPrice(data.price);
            console.log(data);
          }
          if (!data) {
            getProduct(1).then((data) => {
              if (data) {
                setPrice(data.price);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getProduct(1).then((data) => {
        if (data) {
          setPrice(data.price);
        }
      });
    }
  }, []);
  return (
    <PageContainer pageTitle="Death Benefit Agreement">
      <div className="flex flex-row max-sm:flex-col-reverse pt-10 pb-10">
        <div className="flex flex-col w-3/4 gap-y-4">
          <h4 className="font-bold">About this product:</h4>
          <p>Creates a permanent and binding death benefit arrangement.</p>
          <br />
          <h4 className=" font-bold">What's included:</h4>
          <p>
            A single Death Benefit Agreement you need to create, replace or
            confirm the members' death benefit arrangements.
          </p>
          <br />
          <h4 className=" font-bold">Important</h4>
          <p>
            A Death Benefit Agreement is always permanent until the member
            revokes or replaces the Agreement.
          </p>
        </div>

        <div className="flex flex-col max-sm:pb-4 max-sm:w-full w-1/4">
          <div className="flex flex-col border-2 space-y-6 py-4 px-4 rounded-lg w-full">
            <span className="text-lg font-bold">${price} inc GST</span>
            <Button
              onClick={() => navigate("/customer/docs/new-death-benefit")}
            >
              Begin Order
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </PageContainer>
  );
};

export default DeathBenefitPreScreen;
