import { createClient } from "@supabase/supabase-js";
import axios from "axios";

const supabaseUrl = "https://ggthnghactypmulajwth.supabase.co";
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY as string;

export const supabase = createClient(supabaseUrl, supabaseKey);

export const getUser = async () => {
  const { data: session } = await supabase.auth.getSession();
  // console.log(session);
  if (session !== null) {
    const { data: user, error } = await supabase
      .from("customer_info")
      .select("*")
      .eq("auth_id", session?.session?.user.id);
    if (user) {
      return user;
    }
  } else {
    return null;
  }
};

export const getCustomers = async () => {
  const { data, error } = await supabase.from("customer_info").select("*");
  if (data) {
    console.log(data);
    return data;
  } else {
    return null;
  }
};

export const getProduct = async (id: number) => {
  const { data, error } = await supabase
    .from("products")
    .select("*")
    .eq("id", id);
  if (data) {
    return data[0];
  } else {
    return null;
  }
};

export const getCustomPricing = async (auth_id: string, product_id: number) => {
  const { data, error } = await supabase
    .from("custom_pricing")
    .select("*")
    .eq("auth_id", auth_id)
    .eq("product_id", product_id);

  if (data) {
    return data[0];
  } else {
    return null;
  }
};

export const getCustomPricingActive = async (
  auth_id: string,
  product_id: number
) => {
  const { data, error } = await supabase
    .from("custom_pricing")
    .select("*")
    .eq("auth_id", auth_id)
    .eq("product_id", product_id)
    .eq("active", true);

  if (data && data.length > 0) {
    return data[0];
  } else {
    return null;
  }
};

export const getProducts = async () => {
  const { data, error } = await supabase.from("products").select("*");
  if (data) {
    return data;
  } else {
    return null;
  }
};

export const getOrders = async () => {
  const { data, error } = await supabase.from("document_orders").select("*");
  if (data) {
    return data;
  } else {
    return null;
  }
};

export const getOrderDetails = async () => {
  const { data, error } = await supabase
    .from("order_details")
    .select("*")
    .order("created_at", { ascending: false });
  if (data) {
    return data;
  } else {
    return null;
  }
};

export const getUserOrders = async (userId: string) => {
  const { data, error } = await supabase
    .from("order_details")
    .select("*")
    .eq("auth_id", userId)
    .order("created_at", { ascending: false });
  if (data) {
    return data;
  } else {
    console.log(error);
    return null;
  }
};

export const invokeFunction = async (functionName: string, params: any) => {
  const url = `https://ggthnghactypmulajwth.supabase.co/functions/v1/${functionName}`;

  const headers = {
    Authorization: `Bearer ${supabaseKey}`,
    "Content-Type": "application/json",
  };

  var config = {
    method: "post",
    url: url,
    headers: headers,
    data: params,
    crossdomain: true,
  };

  let d = await axios(config);

  return d.data;
};

export const processDocument = async (endpoint: string, params: any) => {
  // TODO: change to env variable
  const url = process.env.REACT_APP_BACKEND_URL;
  const headers = {
    "Allow-Control-Allow-Origin": "*",
  };
  const res = await axios.post(`${url}/api/documents/${endpoint}`, params, {
    headers: headers,
  });
  return res.data;
};

export const sendDocumentsEmail = async (params: any) => {
  const url = `https://ggthnghactypmulajwth.supabase.co/functions/v1/emailDocuments`;

  const headers = {
    Authorization: `Bearer ${supabaseKey}`,
    "Content-Type": "application/json",
  };

  var config = {
    method: "post",
    url: url,
    headers: headers,
    data: params,
    crossdomain: true,
  };
  try {
    let d = await axios(config);
    if (d.status === 546) {
      return {
        message: "Please wait a minute before submitting another request.",
        data: null,
        status: 546,
      };
    }

    return {
      message: d.data.message,
      data: d.data.data,
      status: d.status,
    };
  } catch (e) {
    return {
      message: "Please wait a minute before submitting another request.",
      data: null,
      status: 546,
    };
  }
};

export const submitTrustDeedForm = async (params: any) => {
  const functionName = "submit_trust_deed_form";
  return await invokeFunction(functionName, params);
};
