import React from "react";
import { Editor } from "@tinymce/tinymce-react";

type Props = {
  initialContent?: string;
  onChange: (content: string) => void;
};

const EmailEditor = (props: Props) => {
  return (
    <Editor
      apiKey="pm6ld9qdpcf2fgtdcu0lcai70zggx84qso0es073xiaauxju"
      init={{
        plugins:
          "anchor autolink image link color lists media table visualblocks wordcount",
        toolbar:
          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight  | checklist numlist bullist indent outdent | removeformat",
        tinycomments_author: "JustDocs",
        branding: false,
      }}
      initialValue={props.initialContent}
      onBlur={(e) => props.onChange(e.target.getContent())}
    />
  );
};

export default EmailEditor;
