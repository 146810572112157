import { Button } from "../../ui/button";

import { LifeBuoy, DollarSign, Settings } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown";

import { Dialog, DialogTrigger } from "../../ui/dialog";
import ProductDialog from "./ProductDialog";
import { useEffect, useState } from "react";
import EmailEditorDialogue from "./EmailEditorDialogue";
import DocumentEditorDialog from "./DocumentEditorDialog";

interface Props {
  productId: number;
}

const ProductDropDown = ({ productId }: Props) => {
  const [productOpen, setProductOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [instructionsOpen, setInstructionsOpen] = useState(false);

  return (
    <>
      <Dialog
        open={productOpen}
        onOpenChange={() => setProductOpen(!productOpen)}
      >
        <ProductDialog setOpen={setProductOpen} productId={productId} />
      </Dialog>
      <Dialog
        open={instructionsOpen}
        onOpenChange={() => setInstructionsOpen(!instructionsOpen)}
      >
        <DocumentEditorDialog
          setOpen={setInstructionsOpen}
          productId={productId}
        />
      </Dialog>
      <Dialog open={emailOpen} onOpenChange={() => setEmailOpen(!emailOpen)}>
        <EmailEditorDialogue setOpen={setEmailOpen} productId={productId} />
      </Dialog>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button className="float-right" variant="default">
            Actions
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-56 mr-0">
          <DropdownMenuLabel>Product Actions</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={() => setProductOpen(true)}>
              <DollarSign className="mr-2 h-4 w-4" />
              <span>Edit Product and Pricing</span>
            </DropdownMenuItem>

            <DropdownMenuItem onClick={() => setEmailOpen(true)}>
              <LifeBuoy className="mr-2 h-4 w-4" />
              <span>Edit Email Content</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setInstructionsOpen(true)}>
              <Settings className="mr-2 h-4 w-4" />
              <span>Edit Instructions</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

export default ProductDropDown;
