import React, { useState } from "react";
import PageContainer from "../../containers/PageContainer";
import { SidebarNav } from "../../components/ui/sidebar-nav";
import ProfileForm from "../../components/customers/Settings/ProfileForm";
import BillingForm from "../../components/customers/Settings/BillingForm";

type Props = {};

const Profile = (props: Props) => {
  const [page, setPage] = useState<string>("Profile");
  const sidebarNavItems = [
    {
      title: "Profile",
      href: "/customer/profile",
    },
    {
      title: "Billing",
      href: "/customer/orders",
    },
  ];
  return (
    <PageContainer pageTitle="Settings">
      <div className="flex flex-col space-y-8 pt-12 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="-mx-4 lg:w-1/5">
          <SidebarNav items={sidebarNavItems} setPage={setPage} page={page} />
        </aside>
        <div className="flex-1 lg:max-w-2xl">
          {page === "Profile" ? <ProfileForm /> : <></>}
          {page === "Billing" ? <BillingForm /> : <></>}
        </div>
      </div>
    </PageContainer>
  );
};

export default Profile;
