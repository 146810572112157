import React, { useEffect, useState } from "react";
import { useNavigate, Outlet, Navigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import { OrderProvider } from "../context/Order.context";
import { useUser } from "../context/User.context";
import { getCustomPricing } from "../utils/supabase";

type Props = {
  customer?: boolean;
};

const CustomerRoute = ({}: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [smsfPrice, setSmsfPrice] = React.useState<number>(132);
  const [DbPrice, setDbPrice] = React.useState<number>(69);
  const [indvToCorpPrice, setIndvToCorpPrice] = React.useState<number>(198);

  const navigate = useNavigate();
  const { admin, role, auth_id } = useUser();
  const menuItems = [
    {
      label: "Orders",
      href: "/customer/orders",
    },
    {
      label: "New Document",
      href: "/customer/new-document",
      components: [
        {
          title: "SMSF Deed Update",
          href: "/customer/docs/trust-deed",
          description: `$${smsfPrice} inc GST`,
        },
        // {
        //   title: "Death Benefit Agreement",
        //   href: "/customer/docs/death-benefit",
        //   description: `$${DbPrice} inc GST`,
        // },
        {
          title: "Individual to Corporate Trustee",
          href: "/customer/docs/indv-to-corp-trustee",
          description: `$${indvToCorpPrice} inc GST`,
        },
      ],
    },
    {
      label: "Profile",
      href: "/customer/profile",
    },
  ];

  const checkAdmin = async () => {
    console.log("admin", admin);
    if (role === "admin") {
      navigate("/admin/orders", { replace: true });
    }

    if (auth_id) {
      await getCustomPricing(auth_id, 1).then((res) => {
        console.log(res);
        if (res && res.active === true) {
          setDbPrice(res.price);
        }
      });
      await getCustomPricing(auth_id, 2).then((res) => {
        console.log(res);
        if (res && res.active === true) {
          setSmsfPrice(res.price);
        }
      });
      await getCustomPricing(auth_id, 3).then((res) => {
        console.log(res);
        if (res && res.active === true) {
          setSmsfPrice(res.price);
        }
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log("customer", role);

    checkAdmin();
  }, [auth_id]);
  if (loading) return <NavBar menuItems={[]} />;
  if (role === "admin") return <Navigate to="/admin/orders" />;
  return (
    <OrderProvider>
      <NavBar menuItems={menuItems}>
        <Outlet />
      </NavBar>
    </OrderProvider>
  );
};

export default CustomerRoute;
