import React from "react";
import { BotIcon, MailIcon, Clock4Icon, StickyNoteIcon } from "lucide-react";
import WhiteLogo from "../assets/images/JUSTDOCSWHITE.png";

type Props = {
  page: "login" | "register";
};

const LoginSplash = (props: Props) => {
  const header = props.page === "login" ? "Login to" : "Create an account to:";
  return (
    <div className="px-10 py-6 gap-y-10 flex flex-col min-h-full">
      <div className="flex flex-row w-full ">
        <a href="/">
          <img src={WhiteLogo} alt="logo" className="h-24" />
        </a>
      </div>
      <div className="flex flex-col mx-auto gap-y-10 mt-24 text-left">
        <div className="">
          <h4 className="text-2xl font-bold text-center text-white">
            {header}
          </h4>
        </div>
        <div className="flex gap-x-2 items-center flex-row">
          <StickyNoteIcon size={24} />
          <span className="text-md text-white text-left">
            Create documents on demand
          </span>
        </div>
        <div className="flex gap-x-2 items-center flex-row">
          <MailIcon size={24} />
          <span className="text-md text-white text-left">
            Have your documents emailed to you
          </span>
        </div>
        <div className="flex gap-x-2 items-center flex-row">
          <Clock4Icon size={24} />
          <span className="text-md text-white text-left">
            Ready for use immediately after payment
          </span>
        </div>
      </div>
    </div>
  );
};

export default LoginSplash;
