import React from "react";
import PageContainer from "../../containers/PageContainer";
import { Separator } from "../../components/ui/seperator";
import Contact from "./Contact";

type Props = {};

const About = (props: Props) => {
  return (
    <PageContainer pageTitle="About JustDocs">
      <Separator />
      <p className="pt-6">
        Justdocs provides Australian legal documents online, at affordable
        prices.
        <br />
        <br />
        We are registered with ASIC and the ATO as a Digital Service Provider.
        Our easy-to-use forms, ensure the document creation process is simple
        and fast. Our interface is secure, reliable and easy to navigate.
        <br />
        <br />
        Our documents are written in plain language that make them easy to
        understand. We ensure all our documents are up to date with legislative
        changes.
        <br />
        <br />
        The Justdocs service includes free local support via phone and email,
        we’re here to help individuals, businesses, professionals.
      </p>
      <div className="pb-40" />
    </PageContainer>
  );
};

export default About;
