import React, { useEffect } from "react";
import PageContainer from "../../../containers/PageContainer";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";

import { Tabs, TabsList, TabsTrigger } from "../../ui/tabs";
import { Button } from "../../ui/button";
import { useNavigate } from "react-router-dom";
import { useOrder } from "../../../context/Order.context";
import { getCustomPricingActive, getProduct } from "../../../utils/supabase";
import { supabase, invokeFunction } from "../../../utils/supabase";
import { useUser } from "../../../context/User.context";
import { Blocks } from "react-loader-spinner";

interface DeathBenefitForm {
  smsfName: string;
  firstName: string;
  lastName: string;
  dob: string;
  address: string;
  suburb: string;
  state: string;
  postcode: number;
  beneficiaries: {
    firstName: string;
    lastName: string;
    relation: string;
    percentage: number;
  }[];
}

type Props = { data: DeathBenefitForm; order_id: string };

const DeathBenefit = (props: Props) => {
  const [company, setCompany] = React.useState(false);
  const [trustees, setTrustees] = React.useState(0);
  const [percentageTotal, setPercentageTotal] = React.useState(0);
  const [generatingForm, setGeneratingForm] = React.useState(false);
  const [formSuccess, setFormSuccess] = React.useState(false);
  const [notTotalPercent, setNotTotalPercent] = React.useState(false);
  const [form, setForm] = React.useState<DeathBenefitForm>({
    smsfName: "",
    firstName: "",
    lastName: "",
    dob: "",
    address: "",
    suburb: "",
    state: "",
    postcode: 2000,
    beneficiaries: [],
  });

  const navigate = useNavigate();
  const { setOrder } = useOrder();
  const { auth_id } = useUser();

  const handleForm = async (e: any) => {
    e.preventDefault();
    const total = form.beneficiaries.reduce(
      (acc, beneficery) => acc + beneficery.percentage,
      0
    );
    if (total !== 100) {
      alert("Beneficeries must add up to 100%");
      return;
    }

    const { data, error } = await supabase
      .from("document_orders")
      .update({
        document_data: form,
      })
      .eq("id", props.order_id)
      .select();
    console.log(error);
    if (data) {
      setGeneratingForm(true);
      const createDoc = await invokeFunction("createDeathBenefitAgreement", {
        form: form,
        auth_id: auth_id,
        order_id: props.order_id,
      });
      setGeneratingForm(false);
      setFormSuccess(true);
      console.log(createDoc);
    }
  };

  useEffect(() => {
    if (props.data) {
      setForm(props.data);
    }
  }, [props.data]);

  return (
    <form onSubmit={(e) => handleForm(e)}>
      <div className="flex flex-col gap-y-8 pt-4">
        {generatingForm === true ? (
          <div className="w-full min-h-screen gap-y-4 flex flex-col align-middle items-center mt-20">
            <h2 className="text-4xl font-semibold text-jd-grey">
              We are processing your document. Please don't navigate away from
              this page yet
            </h2>
            <Blocks
              height={100}
              width={100}
              color="#4fa94d"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              visible={true}
            />
          </div>
        ) : formSuccess ? (
          <div className="flex flex-col">
            <h1 className="text-2xl text-jd-green">
              Form Updated and Regenerated
            </h1>
          </div>
        ) : (
          <>
            <div className="flex flex-row w-full">
              <h4 className="font-semibold text-jd-grey text-xl">
                Edit the Details of your Death Benefit Nomination
              </h4>
            </div>
            <div className="flex flex-col gap-y-2">
              <Label>Name of SMSF</Label>
              <Input
                type="text"
                required
                placeholder="SMSF Name"
                onChange={(e) => {
                  setForm({ ...form, smsfName: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row gap-y-2 gap-x-4 pt-6">
              <div className="flex flex-col w-1/2 max-sm:w-full gap-y-2">
                <h4 className="text-bold text-2xl text-jd-grey">
                  Nominee Details
                </h4>
                <span className="text-jd-blue font-semibold">
                  Please enter the details of member making death benefit
                  nomination
                </span>
              </div>
            </div>
            <div className="flex flex-row max-sm:flex-col gap-y-2 gap-x-4 pt-4">
              <div className="flex flex-col max-sm:w-full gap-y-2 w-4/12">
                <Label>First Name</Label>
                <Input
                  required
                  type="text"
                  placeholder="First Name"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      firstName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex flex-col gap-y-2  max-sm:w-full w-4/12">
                <Label>Last Name</Label>
                <Input
                  required
                  type="text"
                  placeholder="Last Name"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      lastName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="flex flex-row gap-y-2 gap-x-4 pt-4">
              <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                <Label>Date of Birth</Label>
                <Input
                  required
                  type="date"
                  placeholder="First Name"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      dob: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="flex flex-col gap-y-2">
              <Label>Street Number and Name</Label>
              <Input
                required
                type="text"
                placeholder="42 Wallaby Way"
                onChange={(e) => {
                  setForm({ ...form, address: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row max-sm:flex-col gap-y-2 gap-x-4">
              <div className="flex flex-col gap-y-2 max-sm:w-full w-6/12">
                <Label>Suburn/Town</Label>
                <Input
                  required
                  type="text"
                  placeholder="Randwick"
                  onChange={(e) => {
                    setForm({ ...form, suburb: e.target.value });
                  }}
                />
              </div>
              <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                <Label>State</Label>
                <Select
                  required
                  onValueChange={(e) => {
                    setForm({ ...form, state: e });
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a state" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="NSW">NSW</SelectItem>
                      <SelectItem value="VIC">VIC</SelectItem>
                      <SelectItem value="QLD">QLD</SelectItem>
                      <SelectItem value="ACT">ACT</SelectItem>
                      <SelectItem value="SA">SA</SelectItem>
                      <SelectItem value="WA">WA</SelectItem>
                      <SelectItem value="NT">NT</SelectItem>
                      <SelectItem value="TAS">TAS</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                <Label>Postcode</Label>
                <Input
                  required
                  type="number"
                  placeholder="2000"
                  onChange={(e) => {
                    setForm({ ...form, postcode: parseInt(e.target.value) });
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row gap-y-2 gap-x-4 pt-6">
              <div className="flex flex-col w-1/2 max-sm:w-full gap-y-2">
                <h4 className="text-bold text-2xl text-jd-grey">
                  Nominated Beneficeries
                </h4>
                <span className="text-jd-blue font-semibold">
                  How many beneficeries do you want to nominate
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-y-2 gap-x-4">
              <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                <Label>How many nominees are there?</Label>
                <Select
                  required
                  defaultValue="0"
                  onValueChange={(e) => {
                    setTrustees(parseInt(e));
                    setForm({
                      ...form,
                      beneficiaries: Array(parseInt(e)).fill({
                        firstName: "",
                        lastName: "",
                        relation: "",
                        percentage: 0,
                      }),
                    });
                  }}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="0">None</SelectItem>
                      <SelectItem value="1">1</SelectItem>
                      <SelectItem value="2">2</SelectItem>
                      <SelectItem value="3">3</SelectItem>
                      <SelectItem value="4">4</SelectItem>
                      <SelectItem value="5">5</SelectItem>
                      <SelectItem value="6">6</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            {Array.from(Array(trustees).keys()).map((i) => (
              <div
                key={`trustee-${i}`}
                className="flex flex-row max-sm:flex-col gap-y-2 gap-x-4 pt-4"
              >
                <Label className=" underline pb-4 text-md max-sm:block hidden">
                  Beneficery {i + 1}
                </Label>
                <div className="flex flex-col gap-y-2 max-sm:w-full w-4/12">
                  <Label>First Name</Label>
                  <Input
                    required
                    type="text"
                    placeholder="First Name"
                    onChange={(e) =>
                      setForm({
                        ...form,
                        beneficiaries: form.beneficiaries.map(
                          (beneficery, index) => {
                            if (index === i) {
                              return {
                                ...beneficery,
                                firstName: e.target.value,
                              };
                            }
                            return beneficery;
                          }
                        ),
                      })
                    }
                  />
                </div>
                <div className="flex flex-col gap-y-2 max-sm:w-full w-4/12">
                  <Label>Last Name</Label>
                  <Input
                    type="text"
                    required
                    placeholder="Last Name"
                    onChange={(e) =>
                      setForm({
                        ...form,
                        beneficiaries: form.beneficiaries.map(
                          (beneficery, index) => {
                            if (index === i) {
                              return {
                                ...beneficery,
                                lastName: e.target.value,
                              };
                            }
                            return beneficery;
                          }
                        ),
                      })
                    }
                  />
                </div>
                <div className="flex flex-col gap-y-2 max-sm:w-full w-4/12">
                  <Label>Relationship</Label>
                  <Select
                    required
                    defaultValue="NA"
                    onValueChange={(e) =>
                      setForm({
                        ...form,
                        beneficiaries: form.beneficiaries.map(
                          (beneficery, index) => {
                            if (index === i) {
                              return {
                                ...beneficery,
                                relation: e,
                              };
                            }
                            return beneficery;
                          }
                        ),
                      })
                    }
                  >
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="NA">-</SelectItem>
                        <SelectItem value="Husband">Husband</SelectItem>
                        <SelectItem value="Wife">Wife</SelectItem>
                        <SelectItem value="Brother">Brother</SelectItem>
                        <SelectItem value="Sister">Sister</SelectItem>
                        <SelectItem value="Child">Child</SelectItem>
                        <SelectItem value="Aunt">Aunt</SelectItem>
                        <SelectItem value="Uncle">Uncle</SelectItem>
                        <SelectItem value="Friend">Friend</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex flex-col gap-y-2 max-sm:w-full w-4/12">
                  <Label>Percent of Benefit to be paid</Label>
                  <Input
                    required
                    type="number"
                    placeholder="0"
                    max={100}
                    min={0}
                    step={1}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        beneficiaries: form.beneficiaries.map(
                          (beneficery, index) => {
                            if (index === i) {
                              return {
                                ...beneficery,
                                percentage: parseInt(e.target.value),
                              };
                            }
                            return beneficery;
                          }
                        ),
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </>
        )}
        <div className="flex flex-row max-sm:flex-col-reverse max-sm:gap-y-4 justify-between gap-y-2 gap-x-4 pt-6">
          <div className="flex flex-col gap-y-2 max-sm:w-full w-2/12">
            <Button
              disabled={generatingForm}
              onClick={() => {
                window.location.reload();
              }}
              variant="secondary"
            >
              Go Back
            </Button>
          </div>
          <div className="flex flex-col gap-y-2 max-sm:w-full w-4/12">
            {!formSuccess && (
              <Button
                type="submit"
                onClick={(e) => handleForm(e)}
                variant="default"
                disabled={generatingForm}
              >
                Save and Regenerate
              </Button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default DeathBenefit;
