import React, { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import NavBarPublic from "../components/NavBarPublic";
import { useUser } from "../context/User.context";
import PageContainer from "../containers/PageContainer";
import Footer from "../components/public/footer";

type Props = {};

const menuItems = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "About",
    href: "/about",
  },
  {
    label: "Documents & Prices",
    href: "/document-prices",
  },
  {
    label: "Contact",
    href: "/contact",
  },
];

const PublicRoute = (props: Props) => {
  return (
    <>
      <NavBarPublic menuItems={menuItems}>
        <PageContainer>
          <Outlet />
          <Footer />
        </PageContainer>
      </NavBarPublic>
    </>
  );
};

export default PublicRoute;
