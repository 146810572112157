import React from "react";
import { DialogContent } from "../../ui/dialog";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { getProduct, supabase } from "../../../utils/supabase";
import { Tabs, TabsList, TabsTrigger } from "../../ui/tabs";
import { Button } from "../../ui/button";

type Props = {
  productId: number;
  setOpen: (close: boolean) => void;
};

const ProductDialog = (props: Props) => {
  const [product, setProduct] = React.useState<any>({
    id: "",
    name: "",
    price: 0,
    active: false,
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [savedSuccessfully, setSavedSuccessfully] = React.useState<
    boolean | null
  >(null);

  const updateProduct = async () => {
    console.log(product);
    const { data, error } = await supabase
      .from("products")
      .upsert(
        {
          id: product.id,
          name: product.name,
          price: product.price,
          active: product.active,
        },
        {
          onConflict: "id",
        }
      )
      .select();

    if (data) {
      setSavedSuccessfully(true);
      setTimeout(() => {
        setSavedSuccessfully(null);
      }, 2000);
    }
  };

  React.useEffect(() => {
    getProduct(props.productId).then((product) => {
      if (product) {
        setProduct(product);
      }
    });
    setLoading(false);
  }, [props.productId]);

  return (
    <DialogContent>
      {!loading && (
        <div className="flex flex-col w-full gap-y-6 px-4 py-6">
          <div className="flex flex-row  gap-x-4">
            <div className="flex flex-col w-full">
              <Label className="text-xl">{product.name}</Label>
              <Label className="text-jd-grey font-normal">
                Edit product information below
              </Label>
            </div>
          </div>
          <div className="flex flex-row gap-y-2 gap-x-4">
            <div className="flex flex-col gap-y-2 w-full">
              <Label>Product Name</Label>
              <Input
                type="text"
                value={product.name}
                onChange={(e) =>
                  setProduct({ ...product, name: e.target.value })
                }
              />
            </div>
          </div>
          <div className="flex flex-row gap-y-2 gap-x-4">
            <div className="flex flex-col gap-y-2 w-full">
              <Label>Price</Label>
              <Input
                type="number"
                onChange={(e) =>
                  setProduct({ ...product, price: parseInt(e.target.value) })
                }
                value={product.price}
              />
            </div>
          </div>
          <div className="flex flex-row gap-y-2 gap-x-4">
            <div className="flex flex-col gap-y-2 w-full">
              <Label>Available</Label>
              <Tabs
                defaultValue={product.active === true ? "yes" : "no"}
                onValueChange={(value) => {
                  setProduct({ ...product, active: value === "yes" });
                }}
                className="w-[200px]"
              >
                <TabsList className="grid w-full grid-cols-2">
                  <TabsTrigger value="no">No</TabsTrigger>
                  <TabsTrigger value="yes">Yes</TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
          </div>
          <div className="flex flex-row gap-y-2 gap-x-4">
            <div className="flex flex-col gap-y-2 w-full">
              {savedSuccessfully !== null && (
                <div
                  className={`py-2 px-4 ${
                    savedSuccessfully === true ? "bg-green-300" : "bg-red-300"
                  }`}
                >
                  {savedSuccessfully === true && (
                    <Label className="text-green-500">Saved Successfully</Label>
                  )}
                  {savedSuccessfully === false && (
                    <Label className="text-red-500">Error Saving</Label>
                  )}
                </div>
              )}
              <Button onClick={() => updateProduct()} variant="default">
                Update Product
              </Button>
            </div>
          </div>
        </div>
      )}
    </DialogContent>
  );
};

export default ProductDialog;
