import React from "react";
import { Button } from "../ui/button";
import HeroImage from "./8278.jpg";
import Hero1 from "../../assets/images/public/hero1.jpg";
import { useNavigate } from "react-router-dom";

type Props = {};

const Hero = (props: Props) => {
  const navigate = useNavigate();

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
        <div className="mr-auto place-self-center lg:col-span-7">
          <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
            Australian Legal Documents online, at affordable prices.
          </h1>
          <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            Our easy to use forms, ensure the document creation process is
            simple and fast.
          </p>
          <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
            <Button
              onClick={() => {
                navigate("/document-prices");
              }}
            >
              Pricing
            </Button>
            <Button
              variant="outline"
              onClick={() => {
                navigate("/document-prices");
              }}
            >
              Learn More
            </Button>
          </div>
        </div>
        <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
          <img src={Hero1} alt="heroimage" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
