import React, { useEffect } from "react";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuViewport,
} from "./ui/navigation-menu";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import {
  getCustomPricing,
  getCustomPricingActive,
  supabase,
} from "../utils/supabase";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import { cn } from "./ui/lib/utils";
import WhiteLogo from "../assets/images/JUSTDOCSWHITE.png";
import { useUser } from "../context/User.context";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { Label } from "./ui/label";
import { Separator } from "./ui/seperator";

type Props = {
  children?: React.ReactNode;
  menuItems?: Array<{
    label: string;
    href: string;
    components?: Array<{
      title: string;
      href: string;
      description: string;
    }>;
  }>;
};

const NavBarPublic = (props: Props) => {
  // get page location iurl
  const location = useLocation();
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = React.useState(false);
  const [smsfPrice, setSmsfPrice] = React.useState<number>(132);
  const [DbPrice, setDbPrice] = React.useState<number>(69);
  const [itocPrice, setItocPrice] = React.useState<number>(198);
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);

  const { admin, auth_id } = useUser();

  const logOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.log(error);
    }
    // window.location.reload();
    navigate("/", { replace: true });
  };

  const LoggedinMenuCustomer = [
    {
      label: "Orders",
      href: "/customer/orders",
    },
    {
      label: "New Document",
      href: "/customer/new-document",
      components: [
        {
          title: "SMSF Deed Update",
          href: "/customer/docs/trust-deed",

          description: `$${smsfPrice} inc GST`,
        },
        // {
        //   title: "Death Benefit Agreement",
        //   href: "/customer/docs/death-benefit",

        //   description: `$${DbPrice} inc GST`,
        // },
        {
          title: "Individual To Corporate Trustee",
          href: "/customer/docs/indv-to-corp-trustee",

          description: `$${itocPrice} inc GST`,
        },
      ],
    },
    {
      label: "Profile",
      href: "/customer/profile",
    },
  ];

  const LoggedinMenuAdmin = [
    {
      label: "Clients",
      href: "/admin/customers",
    },
    {
      label: "Products",
      href: "/admin/products",
    },

    {
      label: "Orders",
      href: "/admin/orders",
    },
  ];

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      console.log("event", event);
      console.log("session", session);
      if (event === "SIGNED_IN") {
        setLoggedIn(true);
      }
    });
    if (auth_id) {
      getCustomPricing(auth_id, 1).then((res) => {
        console.log(res);
        if (res && res.active === true) {
          setDbPrice(res.price);
        }
      });
      getCustomPricing(auth_id, 2).then((res) => {
        console.log(res);
        if (res && res.active === true) {
          setSmsfPrice(res.price);
        }
      });
      getCustomPricing(auth_id, 3).then((res) => {
        console.log(res);
        if (res && res.active === true) {
          setItocPrice(res.price);
        }
      });
    }
    // console.log(location.pathname);
  }, [location, admin, auth_id]);

  return (
    <>
      <div className="flex flex-col max-sm:hidden">
        <div className="pb-2 px-8 bg-jd-blue flex flex-row gap-x-12 w-full text-white">
          <a href="/">
            <img src={WhiteLogo} alt="logo" className="h-20" />
          </a>
          <NavigationMenu className="">
            <NavigationMenuList className="gap-x-16 w-full">
              {props.menuItems?.map((item) =>
                !item.components ? (
                  <NavigationMenuItem
                    key={item.label}
                    className={`${
                      location.pathname === item.href
                        ? "font-bold"
                        : "font-light"
                    }`}
                  >
                    <NavigationMenuLink href={item.href}>
                      {item.label}
                    </NavigationMenuLink>
                  </NavigationMenuItem>
                ) : (
                  <>
                    <NavigationMenuItem className="">
                      <NavigationMenuTrigger>
                        {item.label}
                      </NavigationMenuTrigger>
                      <NavigationMenuContent className="">
                        <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
                          {item.components.map((component) => (
                            <ListItem
                              key={component.title}
                              title={component.title}
                              href={component.href}
                            >
                              {component.description}
                            </ListItem>
                          ))}
                        </ul>
                      </NavigationMenuContent>
                    </NavigationMenuItem>
                  </>
                )
              )}
            </NavigationMenuList>
          </NavigationMenu>
          <div className="ml-auto flex flex-row gap-x-4">
            {loggedIn === true ? (
              <Button
                className="ml-auto my-auto text-jd-blue border-white border-2 hover:bg-jd-blue hover:text-white hover:border-2 hover:border-white"
                variant={"secondary"}
                onClick={() => {
                  logOut();
                }}
              >
                Logout
              </Button>
            ) : (
              <>
                <Button
                  className="ml-auto my-auto text-jd-blue border-white border-2 hover:bg-jd-blue hover:text-white hover:border-2 hover:border-white"
                  variant={"secondary"}
                  onClick={() => {
                    navigate("/login", { replace: true });
                  }}
                >
                  Login
                </Button>
                <Button
                  className="ml-auto my-auto border-2 border-white hover:bg-white hover:text-jd-blue"
                  variant={"default"}
                  onClick={() => {
                    navigate("/register", { replace: true });
                  }}
                >
                  Register
                </Button>
              </>
            )}
          </div>
        </div>
        {loggedIn && (
          <div className="py-3 px-8 bg-jd-blue flex flex-row gap-x-12 w-full text-white">
            <img src={WhiteLogo} alt="logo" className="h-0 w-[210px]" />
            <NavigationMenu className="">
              <NavigationMenuList className="gap-x-12 w-full">
                {admin === false &&
                  (admin !== null || admin !== undefined) &&
                  LoggedinMenuCustomer?.map((item) =>
                    !item?.components ? (
                      <NavigationMenuItem key={item.label}>
                        <NavigationMenuLink href={item.href}>
                          {item.label}
                        </NavigationMenuLink>
                      </NavigationMenuItem>
                    ) : (
                      <>
                        <NavigationMenuItem className="">
                          <NavigationMenuTrigger>
                            {item.label}
                          </NavigationMenuTrigger>
                          <NavigationMenuContent className="">
                            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
                              {item.components.map((component) => (
                                <ListItem
                                  key={component.title}
                                  title={component.title}
                                  href={component.href}
                                >
                                  {component.description}
                                </ListItem>
                              ))}
                            </ul>
                          </NavigationMenuContent>
                        </NavigationMenuItem>
                      </>
                    )
                  )}
                {admin !== false &&
                  (admin !== null || admin !== undefined) &&
                  LoggedinMenuAdmin?.map((item) => (
                    <NavigationMenuItem key={item.label}>
                      <NavigationMenuLink href={item.href}>
                        {item.label}
                      </NavigationMenuLink>
                    </NavigationMenuItem>
                  ))}
              </NavigationMenuList>
            </NavigationMenu>
          </div>
        )}
        {props.children}
      </div>
      <div className="max-sm:block hidden">
        <div className="flex flex-row bg-jd-blue py-4 w-full relative">
          <div className=" ml-4 w-1/6">
            <HamburgerMenuIcon
              color="#FFF"
              width={30}
              height={50}
              onClick={() => setMenuOpen(!menuOpen)}
            />
          </div>
          <div className="w-2/4">
            <img src={WhiteLogo} alt="logo" className="h-10 mx-auto my-auto" />
          </div>
        </div>
        {menuOpen && (
          <div className="absolute z-50 flex flex-col w-full">
            <div className="gap-x-16 py-4 w-full flex-col px-4 bg-jd-blue text-white">
              {loggedIn && (
                <div className="w-full py-2">
                  {admin === false &&
                    (admin !== null || admin !== undefined) &&
                    LoggedinMenuCustomer?.map((item) =>
                      !item?.components ? (
                        <a href={item.href}>
                          <div className="w-full py-2">
                            <Label>{item.label}</Label>
                          </div>
                        </a>
                      ) : (
                        <Accordion
                          type="single"
                          collapsible
                          className="w-full border-0"
                        >
                          <AccordionItem className="border-0" value="item-1">
                            <AccordionTrigger className="">
                              <Label>{item.label}</Label>
                            </AccordionTrigger>
                            <AccordionContent>
                              {item.components.map((component) => (
                                <a href={component.href}>
                                  <div className="ml-4 w-full py-2">
                                    <Label>{component.title}</Label>
                                  </div>
                                </a>
                              ))}
                            </AccordionContent>
                          </AccordionItem>
                        </Accordion>
                      )
                    )}
                  {admin !== false &&
                    (admin !== null || admin !== undefined) &&
                    LoggedinMenuAdmin?.map((item) => (
                      <a href={item.href}>
                        <div className="w-full py-2">
                          <Label>{item.label}</Label>
                        </div>
                      </a>
                    ))}
                </div>
              )}
              <Separator className="my-4" />
              {props.menuItems?.map((item) =>
                !item.components ? (
                  <a href={item.href}>
                    <div className="w-full py-2">
                      <Label>{item.label}</Label>
                    </div>
                  </a>
                ) : (
                  <></>
                )
              )}

              <Separator className="my-4" />
              {loggedIn === true ? (
                <Button
                  className="my-auto w-full text-jd-blue border-white border-2 hover:bg-jd-blue hover:text-white hover:border-2 hover:border-white"
                  variant={"secondary"}
                  onClick={() => {
                    logOut();
                  }}
                >
                  Logout
                </Button>
              ) : (
                <div className="space-y-4">
                  <Button
                    className="w-full my-auto text-jd-blue border-white border-2 hover:bg-jd-blue hover:text-white hover:border-2 hover:border-white"
                    variant={"secondary"}
                    onClick={() => {
                      navigate("/login", { replace: true });
                    }}
                  >
                    Login
                  </Button>
                  <Button
                    className="w-full my-auto border-2 border-white hover:bg-white hover:text-jd-blue"
                    variant={"default"}
                    onClick={() => {
                      navigate("/register", { replace: true });
                    }}
                  >
                    Register
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        {props.children}
      </div>
    </>
  );
};

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";

export default NavBarPublic;
