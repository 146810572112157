import React from "react";

import { useOrder } from "../../../context/Order.context";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableHeader,
  TableCaption,
} from "../../ui/table";

type Props = {
  order: any;
};

const OrderTable = (props: Props) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[10%]">Description</TableHead>
          <TableHead className="w-[10%]">Amount</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell className="font-medium">
            {props.order.description}
          </TableCell>
          <TableCell>${props.order.price}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default OrderTable;
