import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { supabase } from "./utils/supabase";

// Import Routes
import AdminRoute from "./routes/AdminRoute";
import AuthedRoute from "./routes/AuthedRoute";
import CustomerRoute from "./routes/CustomerRoute";

// Import Admin Pages
import EmailTemplates from "./pages/admin/EmailTemplates";

// Import Customer Pages
import Orders from "./pages/customer/Orders";
import Customers from "./pages/admin/Customers";
import Login from "./pages/Login";
import { UserProvider, useUser } from "./context/User.context";
import NewTrustDeed from "./pages/customer/documents/NewTrustDeed";
import NewDeathBenefit from "./pages/customer/documents/NewDeathBenefit";
import Payment from "./pages/customer/Payment";
import PaymentConfirmation from "./pages/customer/PaymentConfirmation";
import Profile from "./pages/customer/Profile";
import ProductManagement from "./pages/admin/ProductManagement";
import AllOrders from "./pages/admin/AllOrders";
import TrustDeedPreScreen from "./pages/customer/documents/information/TrustDeedPreScreen";
import DeathBenefitPreScreen from "./pages/customer/documents/information/DeathBenefitPreScreen";
import PublicRoute from "./routes/PublicRoute";
import Home from "./pages/public/Home";
import Contact from "./pages/public/Contact";
import About from "./pages/public/About";
import DocumentsPrices from "./pages/public/DocumentsPrices";
import PrivacyPolicy from "./pages/public/PrivacyPolicy";
import IndvToCorporateTrusteePreScreen from "./pages/customer/documents/information/IndvToCorporateTrusteePreScreen";
import NewIndvToCorporateTrustee from "./pages/customer/documents/NewIndvToCorporateTrustee";

function App() {
  const [session, setSession] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const { admin } = useUser();

  useEffect(() => {
    setLoading(false);
  }, [admin]);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) return <h1>Loading...</h1>;

  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<PublicRoute />}>
            {/* Routes that don't required authentication */}
            <Route path="/" element={<Home />} />
            <Route path="/document-prices" element={<DocumentsPrices />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Route>
          <Route path="/docs" element={<PublicRoute />}>
            <Route
              path="/docs/death-benefit"
              element={<DeathBenefitPreScreen />}
            />
            <Route path="/docs/trust-deed" element={<TrustDeedPreScreen />} />
          </Route>
          <Route path="/*" element={<Navigate to="/" />} />
          <Route path="/login" element={<Login page="login" />} />
          <Route path="/register" element={<Login page="register" />} />
          <Route path="/reset-password" element={<Login page="password" />} />
          {/* Routes that require authentication */}
          <Route element={<AuthedRoute />}>
            <Route path="/admin" element={<AdminRoute />}>
              <Route path="/admin/customers" element={<Customers />} />
              <Route path="/admin/products" element={<ProductManagement />} />
              <Route path="/admin/orders" element={<AllOrders />} />
              <Route path="/admin/email-editor" element={<EmailTemplates />} />
              <Route path="/admin/profile" element={<Profile />} />
            </Route>
            <Route path="/customer" element={<CustomerRoute />}>
              <Route path="/customer/profile" element={<Profile />} />
              <Route path="/customer/orders" element={<Orders />} />
              <Route
                path="/customer/docs/trust-deed"
                element={<TrustDeedPreScreen />}
              />
              <Route
                path="/customer/docs/new-trust-deed"
                element={<NewTrustDeed />}
              />
              <Route
                path="/customer/docs/death-benefit"
                element={<DeathBenefitPreScreen />}
              />
              <Route
                path="/customer/docs/new-death-benefit"
                element={<NewDeathBenefit />}
              />
              <Route
                path="/customer/docs/indv-to-corp-trustee"
                element={<IndvToCorporateTrusteePreScreen />}
              />
              <Route
                path="/customer/docs/new-indv-to-corp-trustee"
                element={<NewIndvToCorporateTrustee />}
              />
              <Route path="/customer/payment" element={<Payment />} />
              <Route
                path="/customer/payment/success"
                element={<PaymentConfirmation />}
              />
            </Route>
          </Route>
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
