import React, { useEffect, useState } from "react";
import PageContainer from "../../containers/PageContainer";
import ProductsTable from "../../components/admin/Products/ProductsTable";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { getProducts } from "../../utils/supabase";
import ProductsDataTable from "../../components/admin/Products/ProductsDataTable";

type Props = {};

const ProductManagement = (props: Props) => {
  const [products, setProducts] = React.useState<any[]>([]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(products);
    const wb = { Sheets: { Products: ws }, SheetNames: ["Products"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = `JustDocs_Products${fileExtension}`;
    FileSaver.saveAs(data, fileName);
  };

  useEffect(() => {
    getProducts().then((products) => {
      if (products) {
        setProducts(products);
      }
    });
  }, []);
  return (
    <PageContainer pageTitle="Product Management">
      <ProductsDataTable data={products} exportToFile={exportToCSV} />
    </PageContainer>
  );
};

export default ProductManagement;
