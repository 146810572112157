import React, { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";
import { useUser } from "../context/User.context";

type Props = {
  admin?: boolean;
};

const AdminRoute = ({}: Props) => {
  const navigate = useNavigate();
  const { admin, role } = useUser();
  const menuItems = [
    {
      label: "Clients",
      href: "/admin/customers",
    },
    {
      label: "Products",
      href: "/admin/products",
    },

    {
      label: "Orders",
      href: "/admin/orders",
    },
    {
      label: "Profile",
      href: "/admin/profile",
    },
  ];

  const checkAdmin = async () => {
    console.log("admin", admin);
    if (role === "customer") {
      navigate("/customer/orders", { replace: true });
    }
  };

  useEffect(() => {
    checkAdmin();
  }, []);

  if (admin === undefined) return <NavBar menuItems={[]} />;
  return (
    <>
      <NavBar menuItems={menuItems}>
        <Outlet />
      </NavBar>
    </>
  );
};

export default AdminRoute;
