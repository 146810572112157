import React from "react";
import Hero from "../../components/public/hero";
import FeaturesBlocks from "../../components/public/features-blocks";
import Features from "../../components/public/features";
import Pricing from "../../components/public/pricing";
import CTA from "../../components/public/CTA";
type Props = {};

const Home = (props: Props) => {
  return (
    <>
      <Hero />
      {/* <Features /> */}
      <FeaturesBlocks />
      <Pricing />
      <CTA />
    </>
  );
};

export default Home;
