// A react context that stores an order object to be used across components and pages
// In the order object will be the following:
// - order number
// - customer details
// - order details
// - order status

import React from "react";

interface Order {
  form: any;
  price: number;
  product: string;
  description: string;
  order_number?: string;
}

interface OrderContextProps {
  order: Order | null;
  setOrder: (order: Order) => void;
}

const OrderContext = React.createContext<OrderContextProps>({
  order: null,
  setOrder: () => {},
});

interface OrderProviderProps {
  children: React.ReactNode;
}

export const OrderProvider = ({ children }: OrderProviderProps) => {
  const [order, setOrder] = React.useState<any>(null);

  return (
    <OrderContext.Provider value={{ order, setOrder }}>
      {children}
    </OrderContext.Provider>
  );
};

export const useOrder = () => React.useContext(OrderContext);
