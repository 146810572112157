import React, { useState, useEffect } from "react";
import PageContainer from "../../../../containers/PageContainer";
import { Button } from "../../../../components/ui/button";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../../context/User.context";
import Footer from "../../../../components/public/footer";
import { getCustomPricingActive, getProduct } from "../../../../utils/supabase";

type Props = {};

const IndvToCorporateTrusteePreScreen = (props: Props) => {
  const [price, setPrice] = useState<number>(0);
  const navigate = useNavigate();
  const { auth_id } = useUser();

  useEffect(() => {
    if (auth_id) {
      console.log("auth_id", auth_id);
      getCustomPricingActive(auth_id, 3)
        .then((data: any) => {
          if (data) {
            setPrice(data.price);
            console.log(data);
          }
          if (!data) {
            getProduct(3).then((data) => {
              if (data) {
                setPrice(data.price);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getProduct(3).then((data) => {
        if (data) {
          setPrice(data.price);
        }
      });
    }
  }, []);

  return (
    <PageContainer pageTitle="Individual to Corporate Trustee">
      <div className="flex flex-row max-sm:flex-col-reverse pt-10 pb-10">
        <div className="flex flex-col w-3/4 gap-y-4">
          <h4 className="font-bold">About this product</h4>
          <p>
            This product updates the SMSF deed to change the trustees from
            Individual Trustees to a Corporate Trustee (with the option of
            removing 1 or more members).
          </p>
          <br />
          <h4 className=" font-bold">What you recieve:</h4>
          <ul className="list-disc pl-4">
            <li>Change of Trustee Deed</li>
            <li>
              Updated Trust Deed to the latest version of the JustDocs deed
            </li>
            <li>Change of Trustee Consents</li>
            <li>Change of Trustee Minutes</li>
            <li>ATO Trustee Declaration Form</li>
            <li>Change of Trustee Minutes</li>
            <li>ATO Change of Details for Superannuation Entities Form</li>
          </ul>
          <br />
          <h4 className=" font-bold">Important</h4>
          <p>
            The Company that will be trustee, should be setup before beginning
            this order. <br />
            This product cannot be used to change to corporate trustee upon
            death of a member/trustee. <br />
            This product cannot be used if an existing member is under 18 years
            of age. <br />
          </p>
        </div>
        <div className="flex flex-col max-sm:pb-4 max-sm:w-full w-1/4">
          <div className="flex flex-col space-y-6 py-4 w-full border-2 px-4 rounded-lg">
            <span className="font-bold text-lg">${price} inc GST</span>
            <Button
              onClick={() =>
                navigate("/customer/docs/new-indv-to-corp-trustee")
              }
            >
              Begin Order
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </PageContainer>
  );
};

export default IndvToCorporateTrusteePreScreen;
