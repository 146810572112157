import React, { useEffect, useState } from "react";
import PageContainer from "../../../containers/PageContainer";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

import { Tabs, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import { Button } from "../../../components/ui/button";

import {
  getCustomPricingActive,
  getProduct,
  invokeFunction,
} from "../../../utils/supabase";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOrder } from "../../../context/Order.context";
import { supabase } from "../../../utils/supabase";
import { useUser } from "../../../context/User.context";
import { Separator } from "../../../components/ui/seperator";

type Props = {};

interface IndvToCorpTrusteeForm {
  smsfName: string;
  jurisdiction: string;
  address: string;
  suburb: string;
  state: string;
  postcode: number;
  originalEstablishmentDate: string;
  amendedBefore: boolean;
  amendments: number;
  amendmentDates: { date: string; index: number }[];
  isTrusteeCompany: boolean;
  trusteeCompanyName?: string;
  trusteeCompanyACN?: string;
  trustees: {
    givenNames: string;
    familyName: string;
    member: boolean;
    leaving: boolean;
  }[];
  chairPerson: string;
  meetingAddressSame: boolean;
  meetingAddress?: string;
  meetingSuburb?: string;
  meetingState?: string;
  meetingPostcode?: number;
}

const NewIndvToCorporateTrustee = (props: Props) => {
  // Set product ID here as its used a few times throughout
  const productId = 3;

  const [company, setCompany] = React.useState(false);
  const [trustees, setTrustees] = React.useState(2);
  const [amendmentDates, setAmendmentDates] = React.useState<number>(1);
  const [companyNameTemp, setCompanyNameTemp] = React.useState("");
  const [suffixTemp, setSuffixTemp] = React.useState("");
  const [form, setForm] = React.useState<IndvToCorpTrusteeForm>({
    smsfName: "",
    jurisdiction: "NSW",
    address: "",
    suburb: "",
    state: "",
    postcode: 2000,
    originalEstablishmentDate: "",
    amendedBefore: false,
    isTrusteeCompany: false,
    trusteeCompanyName: "",
    trusteeCompanyACN: "",
    trustees: [
      {
        givenNames: " ",
        familyName: " ",
        member: true,
        leaving: false,
      },
      {
        givenNames: " ",
        familyName: " ",
        member: true,
        leaving: false,
      },
    ],
    chairPerson: "",
    meetingAddressSame: true,
    meetingAddress: "",
    meetingSuburb: "",
    meetingState: "",
    meetingPostcode: 2000,
    amendmentDates: [{ date: "", index: 0 }],
    amendments: 0,
  });
  const [loading, setLoading] = React.useState(true);

  const navigate = useNavigate();
  const { setOrder } = useOrder();
  const { auth_id } = useUser();

  const handleForm = async (event: any) => {
    event.preventDefault();
    if (form.meetingAddressSame === true) {
      setForm({
        ...form,
        meetingAddress: form.address,
        meetingSuburb: form.suburb,
        meetingState: form.state,
        meetingPostcode: form.postcode,
      });
    }
    var sbData;
    if (searchParams.get("order_id")) {
      const { data, error } = await supabase
        .from("document_orders")
        .upsert(
          [
            {
              id: searchParams.get("order_id"),
              auth_id: auth_id,
              document_data: form,
              product_id: productId,
              status: "pending",
            },
          ],
          { onConflict: "id" }
        )
        .select();
      sbData = data;
    } else {
      const { data, error } = await supabase
        .from("document_orders")
        .insert([
          {
            auth_id: auth_id,
            document_data: form,
            product_id: productId,
            status: "pending",
          },
        ])
        .select();
      sbData = data;
    }
    if (sbData) {
      const customPricing = await getCustomPricingActive(auth_id, productId);

      const customPrice = customPricing ? customPricing.price : null;

      console.log(customPricing);
      localStorage.setItem("order_number", sbData[0].id);
      const product = await getProduct(productId);
      setOrder({
        form: form,
        product: product.name,
        price: customPrice ? customPrice : product.price,
        description: product.description,
        order_number: sbData[0].id,
      });
      navigate("/customer/payment");
    }
  };

  // Get URL parameter order_id
  const [searchParams, setSearchParams] = useSearchParams();

  const testOrder = async (e: any) => {
    e.preventDefault();
    await invokeFunction("createIndvToCorpDocs", {
      form: form,
      auth_id: auth_id,
      order_id: searchParams.get("order_id"),
    });
    alert("done");
  };

  const saveOrder = async (e: any) => {
    e.preventDefault();
    if (form.meetingAddressSame === true) {
      setForm({
        ...form,
        meetingAddress: form.address,
        meetingSuburb: form.suburb,
        meetingState: form.state,
        meetingPostcode: form.postcode,
      });
    }
    if (searchParams.get("order_id")) {
      const { data, error } = await supabase
        .from("document_orders")
        .upsert(
          [
            {
              id: searchParams.get("order_id"),
              auth_id: auth_id,
              document_data: form,
              product_id: productId,
              status: "pending",
            },
          ],
          { onConflict: "id" }
        )
        .select();
    } else {
      const { data, error } = await supabase
        .from("document_orders")
        .insert([
          {
            auth_id: auth_id,
            document_data: form,
            product_id: productId,
            status: "pending",
          },
        ])
        .select();
    }

    navigate("/customer/orders");
  };

  useEffect(() => {
    const order_id = searchParams.get("order_id");
    if (order_id) {
      const getOrder = async () => {
        const { data, error } = await supabase
          .from("document_orders")
          .select()
          .eq("id", order_id);
        if (data) {
          console.log(data[0].document_data);
          setTrustees(data[0].document_data.trustees.length);
          setForm(data[0].document_data);
          if (data[0].document_data.trusteeCompanyName.includes("PTY")) {
            setCompanyNameTemp(
              data[0].document_data.trusteeCompanyName.split(" PTY")[0]
            );
            setSuffixTemp(
              `PTY${data[0].document_data.trusteeCompanyName.split(" PTY")[1]}`
            );
          } else {
            setCompanyNameTemp(
              data[0].document_data.trusteeCompanyName.split(" PR")[0]
            );
            setSuffixTemp(
              `PR${data[0].document_data.trusteeCompanyName.split(" PR")[1]}`
            );
          }
        }
      };
      getOrder().then((r) => setLoading(false));
    }
    if (!order_id) setLoading(false);
  }, []);

  return (
    <PageContainer pageTitle="Individual to Corporate Trustee">
      {loading === false && (
        <form onSubmit={(e) => handleForm(e)}>
          <div className="flex flex-col gap-y-8 pt-4">
            <div className="flex flex-col pt-6 gap-y-2">
              <Label>
                Name of SMSF<span className="text-red-500">*</span>
              </Label>
              <div className="flex flex-row gap-x-4">
                <div className="w-1/2 max-sm:w-3/4">
                  <Input
                    type="text"
                    required
                    value={form.smsfName}
                    placeholder="SMSF Name"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        smsfName: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col pt-6 gap-y-2">
              <Label>
                Which jurisdiction's laws apply to the Trust Deed?{" "}
                <span className="text-red-500">*</span>
              </Label>
              <div className="flex flex-row gap-x-4">
                <div className="w-1/4">
                  <Select
                    required
                    value={form.jurisdiction}
                    onValueChange={(e) => {
                      setForm({ ...form, jurisdiction: e });
                    }}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="State" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="Australian Captial Territory">
                          Australian Captial Territory
                        </SelectItem>
                        <SelectItem value="New South Wales">
                          New South Wales
                        </SelectItem>
                        <SelectItem value="Northern Territory">
                          Northern Territory
                        </SelectItem>
                        <SelectItem value="Queensland">Queensland</SelectItem>
                        <SelectItem value="South Australia">
                          South Australia
                        </SelectItem>
                        <SelectItem value="Tasmania">Tasmania</SelectItem>
                        <SelectItem value="Victoria">Victoria</SelectItem>
                        <SelectItem value="Western Australia">
                          Western Australia
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-2">
              <Label>
                Street Number and Name<span className="text-red-500">*</span>
              </Label>
              <Input
                type="text"
                required
                value={form.address}
                placeholder="42 Wallaby Way"
                onChange={(e) => {
                  setForm({ ...form, address: e.target.value });
                }}
              />
            </div>
            <div className="flex max-sm:flex-col flex-row gap-y-2 gap-x-4">
              <div className="flex flex-col gap-y-2 max-sm:w-full w-6/12">
                <Label>
                  Suburn/Town<span className="text-red-500">*</span>
                </Label>
                <Input
                  type="text"
                  value={form.suburb}
                  required
                  onChange={(e) => setForm({ ...form, suburb: e.target.value })}
                  placeholder="Randwick"
                />
              </div>
              <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                <Label>
                  State<span className="text-red-500">*</span>
                </Label>
                <Select
                  required
                  value={form.state}
                  defaultValue={form.state ? form.state : "NSW"}
                  onValueChange={(e) => setForm({ ...form, state: e })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a state" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="ACT">ACT</SelectItem>
                      <SelectItem value="NSW">NSW</SelectItem>
                      <SelectItem value="NT">NT</SelectItem>
                      <SelectItem value="QLD">QLD</SelectItem>
                      <SelectItem value="SA">SA</SelectItem>
                      <SelectItem value="TAS">TAS</SelectItem>
                      <SelectItem value="VIC">VIC</SelectItem>
                      <SelectItem value="WA">WA</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex flex-col gap-y-2  max-sm:w-full w-3/12">
                <Label>
                  Postcode<span className="text-red-500">*</span>
                </Label>
                <Input
                  type="number"
                  value={form.postcode}
                  required
                  onChange={(e) =>
                    setForm({ ...form, postcode: parseInt(e.target.value) })
                  }
                  placeholder="2000"
                />
              </div>
            </div>
            <div className="flex flex-col pt-6 gap-y-2">
              <Label>
                What is the date of the original deed which established the
                SMSF? <span className="text-red-500">*</span>
              </Label>
              <div className="flex flex-row gap-x-4">
                <div className="w-1/4">
                  <Input
                    type="date"
                    value={form.originalEstablishmentDate}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        originalEstablishmentDate: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-y-2 gap-x-4 pt-6">
              <div className="flex flex-col w-1/4 max-sm:w-full gap-y-6">
                <Label>Has the Fund's original Deed been amended before?</Label>
                <Tabs
                  defaultValue={form.amendedBefore ? "yes" : "no"}
                  value={form.amendedBefore ? "yes" : "no"}
                  onValueChange={(e) => {
                    setCompany(e === "yes" ? true : false);
                    setForm({
                      ...form,
                      amendedBefore: e === "yes" ? true : false,
                    });
                  }}
                  className="w-[200px]  max-sm:w-full"
                >
                  <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="no">No</TabsTrigger>
                    <TabsTrigger value="yes">Yes</TabsTrigger>
                  </TabsList>
                </Tabs>
              </div>
            </div>
            {form.amendedBefore && (
              <div className="flex flex-col gap-y-2 w-full">
                <div className="flex max-sm:flex-col flex-row gap-y-2 gap-x-4 pt-6">
                  <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                    <Label>How many times has it been amended?</Label>
                    <Select
                      required
                      value={form.amendmentDates?.length.toString()}
                      defaultValue={
                        form.amendmentDates
                          ? form.amendmentDates?.length.toString()
                          : "1"
                      }
                      onValueChange={(e) => {
                        setAmendmentDates(parseInt(e));
                        setForm({
                          ...form,
                          amendmentDates: Array(parseInt(e))
                            .fill({ date: "", index: 0 })
                            .map((trustee, index) => {
                              if (
                                form.amendmentDates &&
                                form.amendmentDates[index]
                              ) {
                                return form.amendmentDates[index];
                              }
                              return trustee;
                            }),
                        });
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="1">1</SelectItem>
                          <SelectItem value="2">2</SelectItem>
                          <SelectItem value="3">3</SelectItem>
                          <SelectItem value="4">4</SelectItem>
                          <SelectItem value="5">5</SelectItem>
                          <SelectItem value="6">6</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                {Array.from(Array(amendmentDates).keys()).map((i) => (
                  <>
                    <div
                      key={`trustee-${i}`}
                      className="flex flex-row max-sm:flex-col gap-y-2 gap-x-4 pt-4"
                    >
                      <Separator className="h-[2px] border-jd-blue hidden max-sm:block" />
                      <div className="flex flex-col gap-y-2  max-sm:w-full w-4/12">
                        <Label>
                          Amendmant {i + 1}
                          <span className="text-red-500">*</span>
                        </Label>
                        <Input
                          value={
                            form.amendmentDates && form.amendmentDates[i].date
                          }
                          onChange={(e) => {
                            setForm({
                              ...form,
                              amendmentDates: form.amendmentDates?.map(
                                (date, index) => {
                                  if (index === i) {
                                    return { date: e.target.value, index: i };
                                  }
                                  return date;
                                }
                              ),
                            });
                          }}
                          type="date"
                          required
                        />
                      </div>
                    </div>
                  </>
                ))}
              </div>
            )}
            <Separator />
            <div className="flex flex-row gap-y-2 gap-x-4 pt-6">
              <div className="flex flex-col w-1/2 max-sm:w-full gap-y-2">
                <h4 className="text-bold text-2xl text-jd-blue">
                  Member/Trustee Details
                </h4>
              </div>
            </div>
            <div className="flex flex-row gap-y-2 gap-x-4">
              <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                <Label>How many members/trustees are there?</Label>
                <Select
                  required
                  value={trustees.toString()}
                  defaultValue={trustees.toString()}
                  onValueChange={(e) => {
                    setTrustees(parseInt(e));
                    setForm({
                      ...form,
                      trustees: Array(parseInt(e))
                        .fill({
                          firstName: "",
                          middleName: "",
                          lastName: "",
                        })
                        .map((trustee, index) => {
                          if (form.trustees[index]) {
                            return form.trustees[index];
                          }
                          return trustee;
                        }),
                    });
                  }}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="2">2</SelectItem>
                      <SelectItem value="3">3</SelectItem>
                      <SelectItem value="4">4</SelectItem>
                      <SelectItem value="5">5</SelectItem>
                      <SelectItem value="6">6</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            {Array.from(Array(trustees).keys()).map((i) => (
              <>
                <div
                  key={`trustee-${i}`}
                  className="flex flex-row max-sm:flex-col justify-start gap-y-2 gap-x-4 pt-4"
                >
                  <Label className="max-sm:block hidden">Member {i + 1}</Label>
                  <Separator className="h-[2px] border-jd-blue hidden max-sm:block" />
                  <div className="flex flex-col gap-y-2  max-sm:w-full w-3/12">
                    <Label>
                      Given Names<span className="text-red-500">*</span>
                    </Label>
                    <Input
                      type="text"
                      required
                      value={form.trustees[i].givenNames}
                      placeholder="First Name"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          trustees: form.trustees.map((trustee, index) => {
                            if (index === i) {
                              return {
                                ...trustee,
                                givenNames: e.target.value,
                              };
                            }
                            return trustee;
                          }),
                        })
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                    <Label>Family Name</Label>
                    <Input
                      type="text"
                      value={form.trustees[i].familyName}
                      placeholder="Family Name"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          trustees: form.trustees.map((trustee, index) => {
                            if (index === i) {
                              return {
                                ...trustee,
                                familyName: e.target.value,
                              };
                            }
                            return trustee;
                          }),
                        })
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 max-sm:w-full w-2/12">
                    <Label>
                      Is this person also a member?
                      <span className="text-red-500">*</span>
                    </Label>
                    <Tabs
                      defaultValue={form.trustees[i].member ? "yes" : "no"}
                      value={form.trustees[i].member ? "yes" : "no"}
                      onValueChange={(e) => {
                        setForm({
                          ...form,
                          trustees: form.trustees.map((trustee, index) => {
                            if (index === i) {
                              return {
                                ...trustee,
                                member: e === "yes" ? true : false,
                              };
                            }
                            return trustee;
                          }),
                        });
                      }}
                      className="w-[200px]  max-sm:w-full"
                    >
                      <TabsList className="grid w-full grid-cols-2">
                        <TabsTrigger value="no">No</TabsTrigger>
                        <TabsTrigger value="yes">Yes</TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </div>
                  {form.trustees[i].member && (
                    <div className="flex flex-col gap-y-2 max-sm:w-full w-2/12">
                      <Label>
                        Is this person leaving the SMSF a member
                        <span className="text-red-500">*</span>
                      </Label>
                      <Tabs
                        defaultValue={form.trustees[i].leaving ? "yes" : "no"}
                        value={form.trustees[i].leaving ? "yes" : "no"}
                        onValueChange={(e) => {
                          setForm({
                            ...form,
                            trustees: form.trustees.map((trustee, index) => {
                              if (index === i) {
                                return {
                                  ...trustee,
                                  leaving: e === "yes" ? true : false,
                                };
                              }
                              return trustee;
                            }),
                          });
                        }}
                        className="w-[200px]  max-sm:w-full"
                      >
                        <TabsList className="grid w-full grid-cols-2">
                          <TabsTrigger value="no">No</TabsTrigger>
                          <TabsTrigger value="yes">Yes</TabsTrigger>
                        </TabsList>
                      </Tabs>
                    </div>
                  )}
                </div>
              </>
            ))}
            <Separator />
            <div className="flex flex-row gap-y-2 gap-x-4 pt-6">
              <div className="flex flex-col w-1/2 max-sm:w-full gap-y-2">
                <h4 className="text-bold text-2xl text-jd-blue ">
                  Details of Company that will be the new Trustee
                </h4>
              </div>
            </div>

            <div className="flex max-sm:flex-col flex-row gap-y-2 gap-x-4 pt-2">
              <div className="flex flex-col w-1/2 max-sm:w-full gap-y-2">
                <Label>
                  Company Name
                  <span className="text-red-500">*</span>
                </Label>
                <div className="flex flex-row">
                  <div className="w-3/4">
                    <Input
                      type="text"
                      value={companyNameTemp}
                      required
                      placeholder="Company Name"
                      onChange={(e) => {
                        setCompanyNameTemp(e.target.value);
                        setForm({
                          ...form,
                          trusteeCompanyName: `${e.target.value} ${suffixTemp}`,
                        });
                      }}
                    />
                  </div>
                  <div className="w-1/4">
                    <Select
                      required
                      value={suffixTemp}
                      onValueChange={(e) => {
                        setSuffixTemp(e);
                        setForm({
                          ...form,
                          trusteeCompanyName: `${companyNameTemp} ${e}`,
                        });
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Suffix" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="PTY LTD">PTY LTD</SelectItem>
                          <SelectItem value="PTY. LTD.">PTY. LTD.</SelectItem>
                          <SelectItem value="PTY. LIMITED">
                            PTY. LIMITED
                          </SelectItem>
                          <SelectItem value="PTY LIMITED">
                            PTY LIMITED
                          </SelectItem>
                          <SelectItem value="PROPRIETARY LTD">
                            PROPRIETARY LTD
                          </SelectItem>
                          <SelectItem value="PROPRIETARY LIMITED">
                            PROPRIETARY LIMITED
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-1/2 max-sm:w-full gap-y-2">
                <Label>
                  ACN of Trustee Company
                  <span className="text-red-500">*</span>
                </Label>
                <Input
                  value={form.trusteeCompanyACN}
                  type="text"
                  required
                  placeholder="365 455 652"
                  onChange={(e) => {
                    setForm({ ...form, trusteeCompanyACN: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row gap-y-2 gap-x-4 pt-6">
              <div className="flex flex-col w-1/2 max-sm:w-full gap-y-2">
                <h4 className="text-bold text-2xl text-jd-grey">
                  Meeting of the Fund's trustees to approve the changes
                </h4>
              </div>
            </div>
            <div className="flex flex-col gap-y-2  max-sm:w-full w-3/12">
              <Label>
                Who will chair the meeting
                <span className="text-red-500">*</span>
              </Label>
              <Select
                value={form.chairPerson}
                defaultValue={form.chairPerson}
                required
                onValueChange={(e) => setForm({ ...form, chairPerson: e })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a chairperson" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {form.trustees
                      .filter(
                        (e) =>
                          e.member === true &&
                          e.leaving === false &&
                          e.givenNames.length >= 1
                      )
                      .map((trustee, index) => {
                        return (
                          <SelectItem
                            key={`trustee-${index}`}
                            value={`${trustee.givenNames} ${trustee.familyName}`}
                          >
                            {trustee.givenNames} {trustee.familyName}
                          </SelectItem>
                        );
                      })}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex flex-col gap-y-2  max-sm:w-full w-3/12">
              <Label>
                Address of Meeting
                <span className="text-red-500">*</span>
              </Label>
              <div className="flex flex-row gap-x-2 items-center">
                <Input
                  className="p-0 max-h-4 w-fit"
                  type="checkbox"
                  onChange={(e) => {
                    setForm({
                      ...form,
                      meetingAddressSame: e.target.checked,
                    });
                  }}
                  checked={form.meetingAddressSame}
                />
                <Label className="float-left">Same address as SMSF </Label>
              </div>
            </div>
            <div className="flex flex-col gap-y-2 ">
              <div className="flex flex-col gap-y-2">
                <Label>
                  Street Number and Name
                  <span className="text-red-500">*</span>
                </Label>
                <Input
                  type="text"
                  required
                  disabled={form.meetingAddressSame}
                  value={
                    form.meetingAddressSame ? form.address : form.meetingAddress
                  }
                  placeholder="42 Wallaby Way"
                  onChange={(e) => {
                    setForm({ ...form, meetingAddress: e.target.value });
                  }}
                />
              </div>
              <div className="flex max-sm:flex-col flex-row gap-y-2 gap-x-4">
                <div className="flex flex-col gap-y-2 max-sm:w-full w-6/12">
                  <Label>
                    Suburn/Town<span className="text-red-500">*</span>
                  </Label>
                  <Input
                    type="text"
                    disabled={form.meetingAddressSame}
                    value={
                      form.meetingAddressSame ? form.suburb : form.meetingSuburb
                    }
                    required
                    onChange={(e) =>
                      setForm({ ...form, meetingSuburb: e.target.value })
                    }
                    placeholder="Randwick"
                  />
                </div>
                <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                  <Label>
                    State<span className="text-red-500">*</span>
                  </Label>
                  <Select
                    required
                    disabled={form.meetingAddressSame}
                    value={
                      form.meetingAddressSame ? form.state : form.meetingState
                    }
                    defaultValue={
                      form.meetingAddressSame ? form.state : form.meetingState
                    }
                    onValueChange={(e) => setForm({ ...form, meetingState: e })}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select a state" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="ACT">ACT</SelectItem>
                        <SelectItem value="NSW">NSW</SelectItem>
                        <SelectItem value="NT">NT</SelectItem>
                        <SelectItem value="QLD">QLD</SelectItem>
                        <SelectItem value="SA">SA</SelectItem>
                        <SelectItem value="TAS">TAS</SelectItem>
                        <SelectItem value="VIC">VIC</SelectItem>
                        <SelectItem value="WA">WA</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex flex-col gap-y-2  max-sm:w-full w-3/12">
                  <Label>
                    Postcode<span className="text-red-500">*</span>
                  </Label>
                  <Input
                    type="number"
                    disabled={form.meetingAddressSame}
                    value={
                      form.meetingAddressSame
                        ? form.postcode
                        : form.meetingPostcode
                    }
                    required
                    onChange={(e) =>
                      setForm({
                        ...form,
                        meetingPostcode: parseInt(e.target.value),
                      })
                    }
                    placeholder="2000"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row max-sm:flex-col-reverse max-sm:gap-x-4 justify-between gap-y-2 gap-x-4 pt-6">
              <div className="flex flex-col gap-y-2 max-sm:w-full  w-2/12">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/customer/orders");
                  }}
                  variant="secondary"
                >
                  Go Back
                </Button>
              </div>
              <div className="flex flex-row max-sm:flex-col gap-x-2 gap-y-2 max-sm:w-full w-2/12">
                <Button onClick={(e) => saveOrder(e)} variant="default">
                  Save
                </Button>
                {/* <Button onClick={(e) => testOrder(e)} variant="destructive">
                  Test
                </Button> */}
                <Button type="submit" variant="default">
                  Save & Continue
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </PageContainer>
  );
};

export default NewIndvToCorporateTrustee;
