import { useEffect, useState } from "react";
import React from "react";
import { Outlet, useNavigate, Navigate } from "react-router-dom";
import { supabase } from "../utils/supabase";
import NavBar from "../components/NavBar";

type Props = {};

const AuthedRoute = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [session, setSession] = useState<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.getSession().then((session) => {
      console.log(session);
      if (!session?.data.session?.user) {
        navigate("/login");
      } else {
        setSession(session);
      }
      setLoading(false);
    });
  }, []);
  if (!loading) {
    if (!session) return <Navigate to="/login" />;
    return <Outlet />;
  } else {
    return <NavBar menuItems={[]} />;
  }
};

export default AuthedRoute;
