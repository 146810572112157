import React, { useEffect, useState } from "react";
import PageContainer from "../../containers/PageContainer";
import OrdersTable from "../../components/admin/Orders/OrdersTable";
import { getOrderDetails } from "../../utils/supabase";
import OrdersDataTable from "../../components/admin/Orders/OrdersDataTable";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

type Props = {};

const AllOrders = (props: Props) => {
  const [orders, setOrders] = useState<any[]>([]);
  const [baseOrders, setBaseOrders] = useState<any[]>([]);

  const columnFilter = async (column: string, filter: string) => {};

  useEffect(() => {
    getOrderDetails().then((orders) => {
      if (orders) {
        setOrders(orders);
        setBaseOrders(orders);
      }
    });
  }, []);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(orders);
    const wb = { Sheets: { Orders: ws }, SheetNames: ["Orders"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = `JustDocs_Customer_Orders${fileExtension}`;
    FileSaver.saveAs(data, fileName);
  };

  return (
    <PageContainer pageTitle="Orders">
      {/* <OrdersTable /> */}
      <OrdersDataTable
        data={orders}
        setData={setOrders}
        exportToFile={exportToCSV}
      />
    </PageContainer>
  );
};

export default AllOrders;
