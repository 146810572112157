import React from "react";
import { DialogContent } from "../../ui/dialog";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Button } from "../../ui/button";
import { supabase } from "../../../utils/supabase";
import { LoaderIcon } from "lucide-react";
type Props = {
  customerId?: string;
  email?: string;
};

const PasswordResetDialog = (props: Props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [pwSent, setPwSent] = React.useState<boolean | null>(null);
  const [pending, setPending] = React.useState<boolean>(false);

  const resetPassword = async () => {
    setPending(true);
    const { data, error } = await supabase
      .from("customer_info")
      .select("email")
      .eq("auth_id", props.customerId);

    if (data && data.length === 1) {
      console.log(data);
      const reset = await supabase.auth.resetPasswordForEmail(data[0].email, {
        redirectTo: "https://justdocs.com.au",
      });

      if (reset.error) {
        console.log(reset.error);
        setPwSent(false);
      }
      if (reset.data) {
        setPwSent(true);
      }
    }
  };

  React.useEffect(() => {
    setLoading(false);
  }, []);

  React.useEffect(() => {
    if (pwSent === true || pwSent === false) {
      setTimeout(() => {
        setPwSent(null);
        setPending(false);
      }, 3000);
    }
  }, [pwSent]);

  return (
    <DialogContent>
      {!loading && (
        <div className="flex flex-col w-full gap-y-6 px-4 py-6">
          <div className="flex flex-row  gap-x-4">
            <div className="flex flex-col w-full">
              <Label className="text-xl">Password Reset</Label>
            </div>
          </div>

          <div className="flex flex-row gap-y-2 gap-x-4">
            <div className="flex flex-col gap-y-2 w-full">
              <Button
                disabled={pending}
                variant="default"
                onClick={resetPassword}
              >
                {pending ? "Sending...." : "Send Password Reset Email"}
              </Button>
            </div>
          </div>
          <div className="flex flex-row gap-y-2 gap-x-4">
            <div className="flex flex-col gap-y-2 w-full">
              {pwSent === true && (
                <Label className="text-green-500">
                  Password reset email sent.
                </Label>
              )}
              {pwSent === false && (
                <Label className="text-red-500">
                  Error sending password reset email.
                </Label>
              )}
            </div>
          </div>
        </div>
      )}
    </DialogContent>
  );
};

export default PasswordResetDialog;
