import React from "react";
import { getProduct } from "../../../utils/supabase";
import { supabase } from "../../../utils/supabase";
import { DialogContent } from "../../ui/dialog";
import { Label } from "../../ui/label";
import EmailEditor from "../EmailTemplates/EmailEditor";
import { Button } from "../../ui/button";
import { Oval } from "react-loader-spinner";

type Props = {
  productId: number;
  setOpen: (close: boolean) => void;
};

const DocumentEditorDialog = (props: Props) => {
  const [documentInstructionsContent, setDocumentInstructionsContent] =
    React.useState<any>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [productName, setProductName] = React.useState<string>("");
  const [saving, setSaving] = React.useState<boolean>(false);
  const [savedSuccessfully, setSavedSuccessfully] = React.useState<
    boolean | null
  >(null);
  // const [save];

  const updateDocumentContent = async () => {
    setSaving(true);
    console.log(props.productId);
    const { data, error } = await supabase
      .from("products")
      .update({
        document_instruction_content: documentInstructionsContent,
      })
      .eq("id", props.productId)
      .select();

    if (data) {
      console.log(data);
      setSaving(false);
      setSavedSuccessfully(true);
      setTimeout(() => {
        setSavedSuccessfully(null);
      }, 2000);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    getProduct(props.productId).then((product) => {
      if (product) {
        setDocumentInstructionsContent(product.document_instruction_content);
        setProductName(product.name);
      }
    });
    setLoading(false);
  }, [props.productId]);
  return (
    <DialogContent className="min-w-[80%]">
      <div className="flex flex-col px-6 py-8">
        <div className="flex flex-row justify-center">
          <Label className="text-xl">Edit {productName} Email</Label>
        </div>
        <Label className="pb-2">Content</Label>
        {!loading && (
          <EmailEditor
            initialContent={documentInstructionsContent}
            onChange={setDocumentInstructionsContent}
          />
        )}
        {savedSuccessfully !== null && (
          <div
            className={`py-2 px-4 ${
              savedSuccessfully === true ? "bg-green-300" : "bg-red-300"
            }`}
          >
            {savedSuccessfully === true && (
              <Label className="text-green-500">Saved Successfully</Label>
            )}
            {savedSuccessfully === false && (
              <Label className="text-red-500">Error Saving</Label>
            )}
          </div>
        )}
        <Button className="mt-8" onClick={updateDocumentContent}>
          {saving ? (
            <Oval color="#FFF" strokeWidth={3} width={20} height={20} />
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </DialogContent>
  );
};

export default DocumentEditorDialog;
