import React, { useEffect, useState } from "react";
import LoginSplash from "../components/LoginSplash";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Link } from "react-router-dom";
import { supabase, getUser } from "../utils/supabase";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/User.context";
import { Label } from "../components/ui/label";
import { Dialog, DialogContent } from "../components/ui/dialog";
import { Separator } from "../components/ui/seperator";

import WhiteLogo from "../assets/images/JUSTDOCSBLUE.png";

type LoginFormProps = {};

type Props = {
  page: "login" | "register" | "password";
};

const baseUrl = process.env.REACT_APP_BASE_URL;

const Login = ({ page }: Props) => {
  const [session, setSession] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname !== "/reset-password") {
      supabase.auth
        .getSession()
        .then(({ data: { session } }) => setSession(session));

      const {
        data: { subscription },
      } = supabase.auth.onAuthStateChange((_event, session) => {
        console.log("session event", _event, session);
        if (session !== null && _event !== "PASSWORD_RECOVERY") {
          navigate("/customer/orders", { replace: true });
        }
      });
      setLoading(false);
      return () => subscription.unsubscribe();
    } else {
      console.log("reset password");
    }
  }, []);
  return (
    <div className="flex flex-cols w-full min-h-screen">
      <div className="w-1/2 bg-jd-blue text-white max-md:hidden">
        <LoginSplash page="login" />
      </div>
      <div className="w-1/2 max-md:w-full">
        {page === "login" ? (
          <LoginForm />
        ) : page === "register" ? (
          <RegisterForm />
        ) : (
          <NewPassword />
        )}
      </div>
    </div>
  );
};

const LoginForm = ({}: LoginFormProps) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [openForgotPassword, setOpenForgotPassword] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const navigate = useNavigate();

  const { storeUserDetails } = useUser();

  const login = async () => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });
    if (error) {
      console.log(error);
      setError(true);
    } else {
      const user = await getUser();
      if (!user) return;
      console.log(user);
      if (user[0].role === "admin") {
        await storeUserDetails(user[0]);
        navigate("/admin/customers", { replace: true });
      } else {
        console.log("customer login");
        await storeUserDetails(user[0]);
        navigate("/customer/orders", { replace: true });
      }
    }
  };

  return (
    <div className="text-center flex flex-col min-h-full max-md:px-10 px-20">
      <div className="my-auto flex flex-col space-y-6 text-center">
        <a href="/">
          {" "}
          <img
            src={WhiteLogo}
            alt="logo"
            className="h-24 mx-auto max-sm:block hidden"
          />
        </a>
        <h1 className="text-2xl font-semibold tracking-tight">Login</h1>
        <p className="text-sm text-muted-foreground">
          Enter your details below to login to your account
        </p>
        <div className="grid gap-6">
          {error ? (
            <div className="w-full bg-red-300 text-red-800 p-2 rounded-md">
              <span>Invalid email/passsword combination. Please try gain</span>
            </div>
          ) : null}
          <Input
            id="email"
            placeholder="name@example.com"
            type="email"
            autoCapitalize="none"
            autoComplete="email"
            autoCorrect="off"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            id="password"
            placeholder="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />

          <Label
            onClick={() => setOpenForgotPassword(true)}
            className="text-xs -my-2 cursor-pointer text-left underline-offset-1 underline text-jd-blue"
          >
            Forgot password?
          </Label>
          <Dialog
            open={openForgotPassword}
            onOpenChange={setOpenForgotPassword}
          >
            <ForgotPasswordDialog />
          </Dialog>

          <Button variant="default" onClick={login} size="default">
            Login
          </Button>
        </div>
        <div className="inset-0 flex items-center">
          <span className="w-full border-t border-1" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">Or</span>
        </div>
        <div className="grid gap-2">
          <Button asChild variant="outline" size="default">
            <Link to="/register">Create An Account</Link>
          </Button>
        </div>
      </div>
    </div>
  );
};

const RegisterForm = () => {
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordStatus, setPasswordStatus] = useState<string>("");

  const [successSignup, setSuccessSignup] = useState<boolean>(false);

  const navigate = useNavigate();

  const passwordCheck = () => {
    if (password.length < 8) {
      setPasswordStatus("Password must be at least 8 characters");
      return false;
    }
    if (password !== confirmPassword) {
      setPasswordStatus("Passwords must match");
      return false;
    }
    if (password === confirmPassword) {
      setPasswordStatus("");
      return true;
    }
  };

  const createAccount = async () => {
    const { data, error } = await supabase.auth.signUp({
      email: email,
      password: password,
    });
    const user = data.user;
    if (error) {
      console.log(error);
    } else {
      const { data, error } = await supabase
        .from("customer")
        .insert([
          {
            auth_id: user?.id,
            first_name: firstName,
            last_name: lastName,
            company: company,
          },
        ])
        .select("*");
      if (data) {
        console.log(data);
        setSuccessSignup(true);
        setTimeout(() => {
          setSuccessSignup(false);
          navigate("/login", { replace: true });
        }, 3000);
      }
    }
  };

  const submitHandler = async () => {
    if (passwordCheck()) {
      // console.log("submit");
      await createAccount();
    }
  };

  return (
    <div className="text-center flex flex-col min-h-full px-20">
      <div className="my-auto flex flex-col space-y-6 text-center">
        <h1 className="text-2xl font-semibold tracking-tight">
          Create an account
        </h1>
        <p className="text-sm text-muted-foreground">
          Enter your details below to create an account
        </p>
        <div className="grid gap-6">
          <Input
            id="email"
            placeholder="name@example.com"
            type="email"
            autoCapitalize="none"
            autoComplete="email"
            autoCorrect="off"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            id="firstName"
            placeholder="First Name"
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Input
            id="lastName"
            placeholder="Last Name"
            type="text"
            onChange={(e) => setLastName(e.target.value)}
          />
          <Input
            id="company"
            placeholder="Company"
            type="text"
            onChange={(e) => setCompany(e.target.value)}
          />
          <Input
            id="password"
            placeholder="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            onBlur={() => passwordCheck()}
          />
          <Input
            id="password"
            placeholder="Confirm Password"
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            onBlur={() => passwordCheck()}
          />
          <p className="text-sm text-red-500">{passwordStatus}</p>
          {successSignup === false ? null : (
            <div className="w-full flex px-4 rounded-lg flex-row bg-green-300 py-2">
              <span className=" text-sm">
                You have successfully created an account. You will be redirected
                to login now
              </span>
            </div>
          )}

          <Button
            variant="default"
            disabled={successSignup}
            onClick={submitHandler}
            size="default"
          >
            Create Account
          </Button>
        </div>
        <div className="inset-0 flex items-center">
          <span className="w-full border-t border-1" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">Or</span>
        </div>
        <div className="grid gap-2">
          <Button
            asChild
            variant="outline"
            size="default"
            disabled={successSignup}
          >
            <Link to="/login">Login</Link>
          </Button>
        </div>
      </div>
    </div>
  );
};

const ForgotPasswordDialog = () => {
  const [sentSuccess, setSentSuccess] = useState<boolean | null>(null);
  const [email, setEmail] = useState<string>("");
  const [sending, setSending] = useState<boolean>(false);
  const sendPasswordReset = async () => {
    setSending(true);
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${baseUrl}/reset-password`,
    });
    console.log(data, error);
    if (error) {
      setSentSuccess(false);
      return;
    }
    setSending(false);
    setSentSuccess(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setSentSuccess(null);
    }, 5000);
  }, [sentSuccess]);

  return (
    <DialogContent>
      <div className="flex flex-col py-10 gap-y-4 px-12">
        <Label>Email</Label>
        <Input placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
        {sentSuccess && (
          <div
            className={`w-full ${
              sentSuccess === true
                ? "bg-green-300 text-green-800"
                : "bg-red-300 text-red-800"
            }`}
          >
            <span>
              {sentSuccess === true
                ? "Password Reset Email was sent successfully"
                : "There was an issue resetting your password"}
            </span>
          </div>
        )}
        <Button
          variant="default"
          disabled={sending}
          onClick={() => {
            sendPasswordReset();
          }}
        >
          Send Password Reset
        </Button>
      </div>
    </DialogContent>
  );
};

const NewPassword = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordCriteriaMet, setPasswordCiteriaMet] = useState<boolean>(false);

  const navigate = useNavigate();

  const passwordCriteriaHandler = () => {
    if (password.length < 8 || password !== confirmPassword) {
      setPasswordCiteriaMet(false);
    } else {
      setPasswordCiteriaMet(true);
    }
  };

  const resetPassword = async () => {
    if (password === confirmPassword) {
      const { data, error } = await supabase.auth.updateUser({
        password: password,
      });
      if (error) {
        console.log(error);
      }
      if (data) {
        alert("Password reset successfully");
        navigate("/login", { replace: true });
      }
    }
  };

  useEffect(() => {
    passwordCriteriaHandler();
  }, [password, confirmPassword]);

  return (
    <div className="text-center flex flex-col min-h-full px-20">
      <div className="my-auto flex flex-col space-y-6 text-center">
        <Label className="">
          Please enter your new password below. <br /> Must be 8 characers
        </Label>
        <Input
          placeholder="Password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        {/* <Label>Confirm Password</Label> */}

        <Input
          className={
            password !== confirmPassword && confirmPassword.length > 0
              ? "border-red-500 border-2"
              : ""
          }
          placeholder="Confirm Password"
          type="password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {password !== confirmPassword && confirmPassword.length > 0 ? (
          <p className="text-sm text-red-500">Passwords must match</p>
        ) : null}
        {/* {sentSuccess && (
          <div
            className={`w-full ${
              sentSuccess === true
                ? "bg-green-300 text-green-800"
                : "bg-red-300 text-red-800"
            }`}
          >
            <span>
              {sentSuccess === true
                ? "Password Reset Email was sent successfully"
                : "There was an issue resetting your password"}
            </span>
          </div>
        )} */}
        <Button
          disabled={!passwordCriteriaMet}
          variant="default"
          onClick={() => {
            resetPassword();
          }}
        >
          Reset Password
        </Button>
        <Separator />
        <Button
          variant="secondary"
          onClick={() => {
            navigate("/login");
          }}
        >
          Return to Login
        </Button>
      </div>
    </div>
  );
};

export default Login;
