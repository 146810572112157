import React, { useEffect, useState } from "react";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Tabs, TabsList, TabsTrigger } from "../../ui/tabs";
import { Button } from "../../ui/button";
import { DialogContent } from "../../ui/dialog";
import { supabase } from "../../../utils/supabase";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectItem,
  SelectGroup,
  SelectContent,
} from "../../ui/select";

type Props = {
  customerId: string;
};

interface CustomPricing {
  id: string | null;
  auth_id: string;
  product_id: number;
  price: number;
  active: boolean;
}

const CustomPricingDialog = ({ customerId }: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<any[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [customPricing, setCustomPricing] = useState<CustomPricing>({
    id: null,
    auth_id: customerId,
    product_id: 0,
    price: 0,
    active: false,
  });
  const [updatedSuccessfully, setUpdatedSuccessfully] = useState<
    null | boolean
  >(null);

  const getProducts = () => {
    supabase
      .from("products")
      .select("*")
      .then((products) => {
        if (products.data) {
          setProducts(products.data);
        }
      });
  };

  const getCustomPricing = () => {
    supabase
      .from("custom_pricing")
      .select("*")
      .eq("id", `${customerId}${selectedProduct}`)
      .eq("product_id", selectedProduct)
      .then((customPricing) => {
        if (customPricing.data) {
          if (customPricing.data.length === 1) {
            console.log(customPricing.data[0]);
            setCustomPricing(customPricing.data[0]);
          }
        }
      });
  };

  const saveCustomPricing = async () => {
    const { data, error } = await supabase
      .from("custom_pricing")
      .upsert(customPricing, { onConflict: "id" });

    if (error) {
      console.log(error);
    } else {
      setUpdatedSuccessfully(true);
      console.log(data);
    }
    setTimeout(() => {
      setUpdatedSuccessfully(null);
    }, 5000);
  };

  useEffect(() => {
    getProducts();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      getCustomPricing();
      setUpdatedSuccessfully(null);
    }
  }, [selectedProduct]);

  return (
    <DialogContent>
      {!loading && (
        <div className="flex flex-col w-full gap-y-6 px-4 py-6">
          <div className="flex flex-row  gap-x-4">
            <div className="flex flex-col w-full">
              <Label className="text-xl">Custom Pricing</Label>
              <Label className="text-jd-grey font-normal">
                Edit Custom Pricing below
              </Label>
            </div>
          </div>
          <div className="flex flex-row gap-y-2 gap-x-4">
            <div className="flex flex-col gap-y-2 w-full">
              <Label>Product Name</Label>
              <Select
                onValueChange={(e) => {
                  setSelectedProduct(e);
                  setCustomPricing({
                    ...customPricing,
                    id: `${customerId}${e}`,
                    product_id: parseInt(e),
                  });
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a product" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {products.map((product) => (
                      <SelectItem key={product.id} value={product.id}>
                        {product.name}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>

          {/* <div className="flex flex-row gap-y-2 gap-x-4">
            <div className="flex flex-col gap-y-2 w-full">
              <Label>Active</Label>
              <Tabs
                value={customPricing.active === true ? "yes" : "no"}
                className="w-[200px]"
                onValueChange={(e) =>
                  setCustomPricing({
                    ...customPricing,
                    active: e === "yes" ? true : false,
                  })
                }
              >
                <TabsList className="grid w-full grid-cols-2">
                  <TabsTrigger value="no">No</TabsTrigger>
                  <TabsTrigger value="yes">Yes</TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
          </div> */}
          {customPricing.active && (
            <div className="flex flex-row gap-y-2 gap-x-4">
              <div className="flex flex-col gap-y-2 w-full">
                <Label>Price</Label>
                <Input
                  type="number"
                  value={customPricing.price}
                  onChange={(e) =>
                    setCustomPricing({
                      ...customPricing,
                      price: parseInt(e.target.value),
                    })
                  }
                />
              </div>
            </div>
          )}
          <div className="flex flex-row gap-y-2 gap-x-4">
            <div className="flex flex-col gap-y-2 w-full">
              <Button onClick={saveCustomPricing} variant="default">
                Update Custom Pricing
              </Button>
              {updatedSuccessfully && (
                <div
                  className={`${
                    updatedSuccessfully === true
                      ? "bg-green-300 text-green-800"
                      : "bg-red-300 text-red-800"
                  }`}
                >
                  {updatedSuccessfully === true
                    ? "Update Successful"
                    : "There was an error updating"}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </DialogContent>
  );
};

export default CustomPricingDialog;
