import React, { useEffect, useState } from "react";
import DocumentsTable from "../../components/public/DocumentsTable";
import { getProducts } from "../../utils/supabase";
type Props = {};

interface Document {
  id: string;
  name: string;
  price: number;
}

const DocumentsPrices = (props: Props) => {
  const [documents, setDocuments] = useState<Document[] | any[]>([]);
  useEffect(() => {
    getProducts().then((res) => {
      console.log(res);
    });
  }, []);
  return (
    <div className="flex flex-col gap-y-6 w-full pb-80">
      <h2 className="text-4xl text-jd-blue font-bold">Documents & Pricing</h2>
      <p>JustDocs provides a wide range of documents for every case</p>
      <DocumentsTable header="Self-Managed Superannuation Fund Documents" />
      <br />
      <DocumentsTable data={[]} header="Company Documents (Coming Soon)" />
      <br />
      <DocumentsTable
        data={[]}
        header="Loan Agreement Documents (Coming Soon)"
      />
    </div>
  );
};

export default DocumentsPrices;
