import React, { useEffect } from "react";
import PageContainer from "../../../containers/PageContainer";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

import { Tabs, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import { Button } from "../../../components/ui/button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useOrder } from "../../../context/Order.context";
import { getCustomPricingActive, getProduct } from "../../../utils/supabase";
import { supabase } from "../../../utils/supabase";
import { useUser } from "../../../context/User.context";

type Props = {};

interface DeathBenefitForm {
  smsfName: string;
  firstName: string;
  lastName: string;
  dob: string;
  address: string;
  suburb: string;
  state: string;
  postcode: number;
  beneficiaries: {
    firstName: string;
    lastName: string;
    relation: string;
    percentage: number;
  }[];
}

const NewDeathBenefit = (props: Props) => {
  const [company, setCompany] = React.useState(false);
  const [trustees, setTrustees] = React.useState(0);
  const [percentageTotal, setPercentageTotal] = React.useState(0);
  const [notTotalPercent, setNotTotalPercent] = React.useState(false);
  const [form, setForm] = React.useState<DeathBenefitForm>({
    smsfName: "",
    firstName: "",
    lastName: "",
    dob: "",
    address: "",
    suburb: "",
    state: "",
    postcode: 2000,
    beneficiaries: [],
  });
  const [loading, setLoading] = React.useState(true);

  const navigate = useNavigate();
  const { setOrder } = useOrder();
  const { auth_id } = useUser();

  // Get URL parameter order_id
  const [searchParams, setSearchParams] = useSearchParams();

  const handleForm = async (e: any) => {
    e.preventDefault();
    const total = form.beneficiaries.reduce(
      (acc, beneficery) => acc + beneficery.percentage,
      0,
    );
    if (total !== 100) {
      alert("Beneficeries must add up to 100%");
      return;
    }

    // event.preventDefault();
    const { data, error } = await supabase
      .from("document_orders")
      .insert([
        {
          auth_id: auth_id,
          document_data: form,
          product_id: 1,
          status: "pending",
        },
      ])
      .select();
    if (data) {
      const customPricing = await getCustomPricingActive(auth_id, 1);

      const customPrice = customPricing ? customPricing.price : null;

      console.log(customPricing);

      localStorage.setItem("order_number", data[0].id);
      const product = await getProduct(1);
      setOrder({
        form: form,
        product: product.name,
        price: customPrice ? customPrice : product.price,
        description: product.description,
        order_number: data[0].id,
      });
      navigate("/customer/payment");
    }
  };

  const saveOrder = async (e: any) => {
    e.preventDefault();
    if (searchParams.get("order_id")) {
      const { data, error } = await supabase
        .from("document_orders")
        .upsert(
          [
            {
              id: searchParams.get("order_id"),
              auth_id: auth_id,
              document_data: form,
              product_id: 1,
              status: "pending",
            },
          ],
          { onConflict: "id" },
        )
        .select();
    } else {
      const { data, error } = await supabase
        .from("document_orders")
        .insert([
          {
            auth_id: auth_id,
            document_data: form,
            product_id: 1,
            status: "pending",
          },
        ])
        .select();
    }

    navigate("/customer/orders");
  };

  useEffect(() => {
    const order_id = searchParams.get("order_id");
    if (order_id) {
      const getOrder = async () => {
        const { data, error } = await supabase
          .from("document_orders")
          .select()
          .eq("id", order_id);
        if (data) {
          setForm(data[0].document_data);
        }
      };
      getOrder().then((r) => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <PageContainer pageTitle="New Death Benefit Agreement">
      {loading === false && (
        <form onSubmit={(e) => handleForm(e)}>
          <div className="flex flex-col gap-y-8 pt-4">
            <div className="flex flex-row w-full">
              <h4 className="font-semibold text-jd-grey text-xl">
                Details of the person the deed will be emailed to
              </h4>
            </div>
            <div className="flex flex-col gap-y-2">
              <Label>Name of SMSF</Label>
              <Input
                type="text"
                value={form.smsfName}
                required
                placeholder="SMSF Name"
                onChange={(e) => {
                  setForm({ ...form, smsfName: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row gap-y-2 gap-x-4 pt-6">
              <div className="flex flex-col w-1/2 max-sm:w-full gap-y-2">
                <h4 className="text-bold text-2xl text-jd-grey">
                  Nominee Details
                </h4>
                <span className="text-jd-blue font-semibold">
                  Please enter the details of member making death benefit
                  nomination
                </span>
              </div>
            </div>
            <div className="flex flex-row max-sm:flex-col gap-y-2 gap-x-4 pt-4">
              <div className="flex flex-col max-sm:w-full gap-y-2 w-4/12">
                <Label>First Name</Label>
                <Input
                  required
                  value={form.firstName}
                  type="text"
                  placeholder="First Name"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      firstName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex flex-col gap-y-2  max-sm:w-full w-4/12">
                <Label>Last Name</Label>
                <Input
                  required
                  value={form.lastName}
                  type="text"
                  placeholder="Last Name"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      lastName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="flex flex-row gap-y-2 gap-x-4 pt-4">
              <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                <Label>Date of Birth</Label>
                <Input
                  required
                  value={form.dob}
                  type="date"
                  placeholder="First Name"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      dob: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="flex flex-col gap-y-2">
              <Label>Street Number and Name</Label>
              <Input
                required
                value={form.address}
                type="text"
                placeholder="42 Wallaby Way"
                onChange={(e) => {
                  setForm({ ...form, address: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row max-sm:flex-col gap-y-2 gap-x-4">
              <div className="flex flex-col gap-y-2 max-sm:w-full w-6/12">
                <Label>Suburn/Town</Label>
                <Input
                  required
                  value={form.suburb}
                  type="text"
                  placeholder="Randwick"
                  onChange={(e) => {
                    setForm({ ...form, suburb: e.target.value });
                  }}
                />
              </div>
              <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                <Label>State</Label>
                <Select
                  required
                  defaultValue={form.state}
                  value={form.state}
                  onValueChange={(e) => {
                    setForm({ ...form, state: e });
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a state" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="NSW">NSW</SelectItem>
                      <SelectItem value="VIC">VIC</SelectItem>
                      <SelectItem value="QLD">QLD</SelectItem>
                      <SelectItem value="ACT">ACT</SelectItem>
                      <SelectItem value="SA">SA</SelectItem>
                      <SelectItem value="WA">WA</SelectItem>
                      <SelectItem value="NT">NT</SelectItem>
                      <SelectItem value="TAS">TAS</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                <Label>Postcode</Label>
                <Input
                  required
                  value={form.postcode}
                  type="number"
                  placeholder="2000"
                  onChange={(e) => {
                    setForm({ ...form, postcode: parseInt(e.target.value) });
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row gap-y-2 gap-x-4 pt-6">
              <div className="flex flex-col w-1/2 max-sm:w-full gap-y-2">
                <h4 className="text-bold text-2xl text-jd-grey">
                  Nominated Beneficeries
                </h4>
                <span className="text-jd-blue font-semibold">
                  How many beneficeries do you want to nominate
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-y-2 gap-x-4">
              <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                <Label>How many nominees are there?</Label>
                <Select
                  required
                  value={form.beneficiaries.length.toString()}
                  defaultValue={form.beneficiaries.length.toString()}
                  onValueChange={(e) => {
                    setTrustees(parseInt(e));
                    setForm({
                      ...form,
                      beneficiaries: Array(parseInt(e)).fill({
                        firstName: "",
                        lastName: "",
                        relation: "",
                        percentage: 0,
                      }),
                    });
                  }}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="0">None</SelectItem>
                      <SelectItem value="1">1</SelectItem>
                      <SelectItem value="2">2</SelectItem>
                      <SelectItem value="3">3</SelectItem>
                      <SelectItem value="4">4</SelectItem>
                      <SelectItem value="5">5</SelectItem>
                      <SelectItem value="6">6</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            {form.beneficiaries.map((item, i) => (
              <div
                key={`trustee-${i}`}
                className="flex flex-row max-sm:flex-col gap-y-2 gap-x-4 pt-4"
              >
                <Label className=" underline pb-4 text-md max-sm:block hidden">
                  Beneficery {i + 1}
                </Label>
                <div className="flex flex-col gap-y-2 max-sm:w-full w-4/12">
                  <Label>First Name</Label>
                  <Input
                    required
                    type="text"
                    value={form.beneficiaries[i].firstName}
                    placeholder="First Name"
                    onChange={(e) =>
                      setForm({
                        ...form,
                        beneficiaries: form.beneficiaries.map(
                          (beneficery, index) => {
                            if (index === i) {
                              return {
                                ...beneficery,
                                firstName: e.target.value,
                              };
                            }
                            return beneficery;
                          },
                        ),
                      })
                    }
                  />
                </div>
                <div className="flex flex-col gap-y-2 max-sm:w-full w-4/12">
                  <Label>Last Name</Label>
                  <Input
                    type="text"
                    required
                    value={form.beneficiaries[i].lastName}
                    placeholder="Last Name"
                    onChange={(e) =>
                      setForm({
                        ...form,
                        beneficiaries: form.beneficiaries.map(
                          (beneficery, index) => {
                            if (index === i) {
                              return {
                                ...beneficery,
                                lastName: e.target.value,
                              };
                            }
                            return beneficery;
                          },
                        ),
                      })
                    }
                  />
                </div>
                <div className="flex flex-col gap-y-2 max-sm:w-full w-4/12">
                  <Label>Relationship</Label>
                  <Select
                    required
                    defaultValue={form.beneficiaries[i].relation}
                    onValueChange={(e) =>
                      setForm({
                        ...form,
                        beneficiaries: form.beneficiaries.map(
                          (beneficery, index) => {
                            if (index === i) {
                              return {
                                ...beneficery,
                                relation: e,
                              };
                            }
                            return beneficery;
                          },
                        ),
                      })
                    }
                  >
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="NA">-</SelectItem>
                        <SelectItem value="Husband">Husband</SelectItem>
                        <SelectItem value="Wife">Wife</SelectItem>
                        <SelectItem value="Brother">Brother</SelectItem>
                        <SelectItem value="Sister">Sister</SelectItem>
                        <SelectItem value="Child">Child</SelectItem>
                        <SelectItem value="Aunt">Aunt</SelectItem>
                        <SelectItem value="Uncle">Uncle</SelectItem>
                        <SelectItem value="Friend">Friend</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex flex-col gap-y-2 max-sm:w-full w-4/12">
                  <Label>Percent of Benefit to be paid</Label>
                  <Input
                    required
                    type="number"
                    value={form.beneficiaries[i].percentage}
                    placeholder="0"
                    max={100}
                    min={0}
                    step={1}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        beneficiaries: form.beneficiaries.map(
                          (beneficery, index) => {
                            if (index === i) {
                              return {
                                ...beneficery,
                                percentage: parseInt(e.target.value),
                              };
                            }
                            return beneficery;
                          },
                        ),
                      })
                    }
                  />
                </div>
              </div>
            ))}
            <div className="flex flex-row max-sm:flex-col-reverse max-sm:gap-x-4 justify-between gap-y-2 gap-x-4 pt-6">
              <div className="flex flex-col gap-y-2 max-sm:w-full  w-2/12">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/customer/orders");
                  }}
                  variant="secondary"
                >
                  Go Back
                </Button>
              </div>
              <div className="flex flex-row max-sm:flex-col gap-x-2 gap-y-2 max-sm:w-full w-2/12">
                <Button onClick={(e) => saveOrder(e)} variant="default">
                  Save
                </Button>
                <Button type="submit" variant="default">
                  Save & Continue
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </PageContainer>
  );
};

export default NewDeathBenefit;
