import React, { useEffect, useState } from "react";
import PageContainer from "../../containers/PageContainer";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardDetails from "../../components/customers/Payment/CardDetails";
import { getUser, invokeFunction } from "../../utils/supabase";

import OrderTable from "../../components/customers/Payment/OrderTable";
import { useOrder } from "../../context/Order.context";
import { useNavigate } from "react-router-dom";
import { Separator } from "../../components/ui/seperator";

type Props = {};

const Payment = (props: Props) => {
  const [clientSecret, setClientSecret] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userDetails, setUserDetails] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    auth_id: "",
  });
  const [saveCustomer, setSaveCustomer] = useState<boolean>(false);

  const { order } = useOrder();
  const navigate = useNavigate();

  const stripeDev =
    "pk_test_51LKZpmBbZ3QdjuSsfd5Nq5a4Ac8S2Bj92niXyoSdzICcHtXp3HkjzoIKSPq0hs84arXMls0VR3kLidroByks73wP00QsuBczyM";
  const stripeProd =
    "pk_live_51LKZpmBbZ3QdjuSsGgzPvteKdyRGeUoOKpVO94299VFcOlPH9rtvHi1a72ODVil1TSzUUwT25KYBXMbH5BksU678000XmEB1nZ";

  const stripePromise = loadStripe(
    window.location.href.includes("localhost") ||
      window.location.href.includes("dev.justdocs.com.au")
      ? stripeDev
      : stripeProd
  );

  const devEnv =
    window.location.href.includes("localhost") ||
    window.location.href.includes("dev.justdocs.com.au");

  useEffect(() => {
    getUser().then((user) => {
      if (user) {
        setUserDetails({
          firstName: user[0].first_name,
          lastName: user[0].last_name,
          email: user[0].email,
          auth_id: user[0].auth_id,
        });

        invokeFunction("stripeCreatePaymentIntent", {
          dev: devEnv,
          price: order?.price ? order.price : 100,
          description: order?.product ? order.product : "",
          createCustomer: true,
          email: user[0].email,
          name: `${user[0].first_name} ${user[0].last_name}`,
          auth_id: user[0].auth_id,
        }).then((data) => {
          console.log(data);
          setClientSecret(data.clientSecret);
        });
      }
    });
    // Create PaymentIntent as soon as the page loads
    if (!order || !order.price || !order.product) {
      alert(
        "There was an issue please try again. If this continues please contact support."
      );
      navigate("/customer/new-document", { replace: true });
      return;
    }
    console.log(userDetails);

    setIsLoading(false);
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <PageContainer pageTitle="Payment">
      {!isLoading && (
        <div className="flex flex-col gap-y-8 pt-4">
          <div className="flex flex-row w-full">
            <h4 className="font-semibold text-jd-grey text-xl">
              Billing Details
            </h4>
          </div>
          <div className="flex flex-row max-sm:flex-col w-full gap-x-4">
            <div className="flex flex-col gap-y-2 max-sm:w-full w-1/3">
              <Label>First Name</Label>
              <Input
                type="text"
                value={userDetails.firstName}
                placeholder="First Name"
              />
            </div>
            <div className="flex flex-col gap-y-2 max-sm:w-full w-1/3">
              <Label>Last Name</Label>
              <Input
                type="text"
                value={userDetails.lastName}
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className="flex flex-row max-sm:flex-col w-full gap-x-4">
            <div className="flex flex-col gap-y-2 max-sm:w-full w-1/3">
              <Label>Email</Label>
              <Input
                type="text"
                value={userDetails.email}
                placeholder="john@email.com"
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <Label>Street Number and Name</Label>
            <Input type="text" placeholder="42 Wallaby Way" />
          </div>
          <div className="flex flex-row max-sm:flex-col gap-y-2 gap-x-4">
            <div className="flex flex-col gap-y-2 max-sm:w-full w-6/12">
              <Label>Suburn/Town</Label>
              <Input type="text" placeholder="Randwick" />
            </div>
            <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
              <Label>State</Label>
              <Select>
                <SelectTrigger>
                  <SelectValue placeholder="Select a state" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="NSW">NSW</SelectItem>
                    <SelectItem value="VIC">VIC</SelectItem>
                    <SelectItem value="QLD">QLD</SelectItem>
                    <SelectItem value="ACT">ACT</SelectItem>
                    <SelectItem value="SA">SA</SelectItem>
                    <SelectItem value="WA">WA</SelectItem>
                    <SelectItem value="NT">NT</SelectItem>
                    <SelectItem value="TAS">TAS</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
              <Label>Postcode</Label>
              <Input type="number" placeholder="2000" />
            </div>
          </div>
          <Separator />
          <div className="flex flex-row w-full pt-4">
            <h4 className="font-semibold text-jd-grey text-xl">
              Order Details
            </h4>
          </div>
          <div className="flex flex-row max-sm:flex-col-reverse w-full gap-x-4">
            <div className="flex flex-col gap-y-2 max-md:w-2/3  max-sm:w-full w-1/3">
              {clientSecret && (
                <Elements
                  options={{
                    clientSecret: clientSecret,
                    appearance: { theme: "stripe" },
                  }}
                  stripe={stripePromise}
                >
                  <CardDetails clientSecret={clientSecret} />
                </Elements>
              )}
            </div>
            <div className="flex flex-col gap-y-2 max-sm:w-full max-sm:pb-8 w-2/3">
              <OrderTable order={order} />
            </div>
          </div>
        </div>
      )}
    </PageContainer>
  );
};

export default Payment;
