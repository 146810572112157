import React, { useState } from "react";
import {
  Row,
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  FilterFn,
  SortingFn,
  sortingFns,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";

import {
  ArrowUpDown,
  ChevronDown,
  CrossIcon,
  DollarSign,
  LifeBuoy,
  MoreHorizontal,
} from "lucide-react";
import { Tabs, TabsContent, TabsTrigger, TabsList } from "../../ui/tabs";
import { Button } from "../../ui/button";
import { Checkbox } from "../../ui/checkbox";
import { Label } from "@radix-ui/react-label";
import { Oval } from "react-loader-spinner";
import { supabase, invokeFunction } from "../../../utils/supabase";
import { TrashIcon } from "lucide-react";
import { Dialog, DialogContent } from "../../ui/dialog";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../ui/dropdown";
import { Input } from "../../ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import { Cross1Icon, Cross2Icon } from "@radix-ui/react-icons";
import moment from "moment";
import {
  getOrderDetails,
  getOrders,
  sendDocumentsEmail,
} from "../../../utils/supabase";

import ProductDropDown from "./ProductDropDown";

interface CustomerColumns {
  customer: string;
  first_name: string;
  last_name: string;
  document_data: any;
  email: string;
  name: string;
  order_name: string;
  status: string;
  price: number;
  paid: string;
  created_at: string;
  actions: string;
}

const columns: ColumnDef<CustomerColumns>[] = [
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="px-0 w-full items-center justify-start"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Product Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
    enableGlobalFilter: true,
  },
  {
    accessorKey: "description",
    enableSorting: true,
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="px-0 w-full items-center justify-start"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Product Description
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: "price",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="px-0 w-full items-center justify-start"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Standard Price
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return <div>${row.original.price.toFixed(2)}</div>;
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
];

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

interface DataTableProps<TData> {
  //   columns: ColumnDef<TData, TValue>[];
  data: TData[];
  exportToFile?: () => void;
}

interface EmailError {
  message: string;
  error: boolean;
}

type Props = {};

const ProductsDataTable = ({ data, exportToFile }: DataTableProps<any>) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = useState("");

  const [additionalInfo, setAdditionalInfo] = React.useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<EmailError>({
    error: false,
    message: "",
  });
  const [selectDocumentError, setSelectDocumentError] =
    useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean | null>(null);
  const [sendDocument, setSendDocument] = useState<boolean>(false);
  const [sendInvoice, setSendInvoice] = useState<boolean>(false);
  const [sendInstructions, setSendInstructions] = useState<boolean>(false);
  const [editsAllowed, setEditsAllowed] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const sendEmail = async (orderId: string, email: string) => {
    if (email.length < 5 || !email.includes("@") || !email.includes(".")) {
      setEmailError({
        error: true,
        message: "Please enter a valid email",
      });
      return;
    }
    if (sendDocument === false && sendInstructions === false) {
      setSelectDocumentError(true);
      return;
    }
    setEmailSent(false);
    const { data, error } = await supabase
      .from("document_orders")
      .update({
        status: "Paid",
      })
      .eq("id", orderId)
      .select();
    console.log(data, error);
    const dor = data && data[0];
    if (data && data.length === 1) {
      try {
        const d = await sendDocumentsEmail({
          orderId: dor.id,
          email: email,
          invoice: sendInvoice,
          instructions: sendInstructions,
          document: sendDocument,
        });
        if (d.status === 546) {
          setEmailSent(false);
          setEmailError({
            error: true,
            message: d.message,
          });
          return;
        }

        setEmailSent(true);
        setEmailError({
          error: false,
          message: d.message,
        });
      } catch (e) {
        console.log(e);
        setEmailSent(false);
        setEmailError({
          error: true,
          message: "Something went wrong, please try again in a couple minutes",
        });
      }
    }
  };

  const downloadDocument = (documentUrl: string) => {
    const documentLink = document.createElement("a");
    documentLink.href = documentUrl;
    documentLink.target = "_blank";
    document.body.appendChild(documentLink);
    documentLink.click();
  };

  // const editableChangehandler = async (orderId: string, e: any) => {
  //   setOrders(
  //     orders.map((o) => {
  //       if (o.id === orderId) {
  //         o.order_editable = e === "Editable" ? true : false;
  //       }
  //       return o;
  //     })
  //   );

  //   const { data, error } = await supabase
  //     .from("document_orders")
  //     .update({
  //       order_editable: e === "Editable" ? true : false,
  //     })
  //     .eq("id", orderId)
  //     .select();

  //   if (error) {
  //     console.log(error);
  //     alert("There was an issue updating the order. Try again");
  //   }
  // };

  const deleteOrder = async (orderId: string) => {
    const { data, error } = await supabase
      .from("document_orders")
      .delete()
      .eq("id", orderId)
      .select();
    if (error) {
      console.log(error);
      alert("There was an issue deleting the order. Try again");
    }
  };

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter,
    },
  });

  return (
    <div className="w-full">
      <div className="flex items-center gap-x-2 py-4">
        <Input
          placeholder="Search"
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(value.target.value)}
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Columns <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
        <Button onClick={exportToFile}>Download</Button>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <>
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                    <TableCell>
                      <ProductDropDown productId={parseInt(row.original.id)} />
                    </TableCell>
                  </TableRow>
                </>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        {/* <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div> */}
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductsDataTable;
