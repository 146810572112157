import { Button } from "../../ui/button";

import { LifeBuoy, DollarSign, Settings } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown";
import { Dialog } from "../../ui/dialog";
import CustomPricingDialog from "./CustomPricingDialog";
import { useState } from "react";
import PasswordResetDialog from "./PasswordResetDialog";

interface Props {
  customerId: string;
}

const CustomerDropDown = ({ customerId }: Props) => {
  const [openCustomPricing, setOpenCustomPricing] = useState<boolean>(false);
  const [openPasswordReset, setOpenPasswordReset] = useState<boolean>(false);
  return (
    <>
      <Dialog
        open={openCustomPricing}
        onOpenChange={() => setOpenCustomPricing(!openCustomPricing)}
      >
        <CustomPricingDialog customerId={customerId} />
      </Dialog>
      <Dialog
        open={openPasswordReset}
        onOpenChange={() => setOpenPasswordReset(!openPasswordReset)}
      >
        <PasswordResetDialog customerId={customerId} />
      </Dialog>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="default">Actions</Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-56 mr-0">
          <DropdownMenuLabel>Customer Actions</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={() => setOpenCustomPricing(true)}>
              <DollarSign className="mr-2 h-4 w-4" />
              <span>Custom Pricing</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setOpenPasswordReset(true)}>
              <LifeBuoy className="mr-2 h-4 w-4" />
              <span>Reset Password</span>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Settings className="mr-2 h-4 w-4" />
              <span>Disable User</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

export default CustomerDropDown;
