import React from "react";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

import { Tabs, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import { Button } from "../../../components/ui/button";

import { useNavigate } from "react-router-dom";
import { supabase, invokeFunction } from "../../../utils/supabase";
import { useUser } from "../../../context/User.context";
import { Separator } from "../../../components/ui/seperator";
import { Blocks } from "react-loader-spinner";

interface TrustDeedForm {
  smsfName: string;
  address: string;
  suburb: string;
  state: string;
  postcode: number;
  isTrusteeCompany: boolean;
  trusteeCompanyName?: string;
  trusteeCompanyACN?: string;
  trustees: {
    firstName: string;
    middleName: string;
    lastName: string;
  }[];
  chairPerson: string;
}

type Props = { data: TrustDeedForm; order_id: string };

const SMSFDeedUpdate = (props: Props) => {
  const [company, setCompany] = React.useState(false);
  const [trustees, setTrustees] = React.useState(2);
  const [generatingForm, setGeneratingForm] = React.useState(false);
  const [formSuccess, setFormSuccess] = React.useState(false);
  const [companyNameTemp, setCompanyNameTemp] = React.useState("");
  const [suffixTemp, setSuffixTemp] = React.useState("");
  const [form, setForm] = React.useState<TrustDeedForm>({
    smsfName: "",
    address: "",
    suburb: "",
    state: "",
    postcode: 2000,
    isTrusteeCompany: false,
    trusteeCompanyName: "",
    trusteeCompanyACN: "",
    trustees: [
      {
        firstName: " ",
        middleName: " ",
        lastName: " ",
      },
      {
        firstName: " ",
        middleName: " ",
        lastName: " ",
      },
    ],
    chairPerson: "",
  });

  const { auth_id } = useUser();

  const handleForm = async (event: any) => {
    event.preventDefault();
    const { data, error } = await supabase
      .from("document_orders")
      .update({
        document_data: form,
      })
      .eq("id", props.order_id)
      .select();
    console.log(error);
    if (data) {
      setGeneratingForm(true);
      const createDoc = await invokeFunction("createTrustDeedPDF", {
        form: form,
        auth_id: auth_id,
        order_id: props.order_id,
      });
      setGeneratingForm(false);
      setFormSuccess(true);
      console.log(createDoc);
    }
  };

  React.useEffect(() => {
    if (props.data) {
      setForm(props.data);
    }
    // return () => {};
  }, [props.data]);

  return (
    <>
      {/* <form onSubmit={(e) => handleForm(e)}> */}

      <div className="flex flex-col gap-y-8 pt-4">
        {generatingForm === true ? (
          <div className="w-full min-h-screen gap-y-4 flex flex-col align-middle items-center mt-20">
            <h2 className="text-4xl font-semibold text-jd-grey">
              We are processing your document. Please don't navigate away from
              this page yet
            </h2>
            <Blocks
              height={100}
              width={100}
              color="#4fa94d"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              visible={true}
            />
          </div>
        ) : formSuccess ? (
          <div className="flex flex-col">
            <h1 className="text-2xl text-jd-green">
              Form Updated and Regenerated
            </h1>
          </div>
        ) : (
          <>
            <div className="flex flex-col pt-6 gap-y-2">
              <Label>
                Name of SMSF<span className="text-red-500">*</span>
              </Label>
              <Input
                type="text"
                value={form.smsfName}
                required
                placeholder="SMSF Name"
                onChange={(e) => {
                  setForm({ ...form, smsfName: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-col gap-y-2">
              <Label>
                Street Number and Name<span className="text-red-500">*</span>
              </Label>
              <Input
                type="text"
                value={form.address}
                required
                placeholder="42 Wallaby Way"
                onChange={(e) => {
                  setForm({ ...form, address: e.target.value });
                }}
              />
            </div>
            <div className="flex max-sm:flex-col flex-row gap-y-2 gap-x-4">
              <div className="flex flex-col gap-y-2 max-sm:w-full w-6/12">
                <Label>
                  Suburn/Town<span className="text-red-500">*</span>
                </Label>
                <Input
                  type="text"
                  value={form.suburb}
                  required
                  onChange={(e) => setForm({ ...form, suburb: e.target.value })}
                  placeholder="Randwick"
                />
              </div>
              <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                <Label>
                  State<span className="text-red-500">*</span>
                </Label>
                <Select
                  required
                  // defaultValue={form.state}
                  value={form.state}
                  onValueChange={(e) => setForm({ ...form, state: e })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a state" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="NSW">NSW</SelectItem>
                      <SelectItem value="VIC">VIC</SelectItem>
                      <SelectItem value="QLD">QLD</SelectItem>
                      <SelectItem value="ACT">ACT</SelectItem>
                      <SelectItem value="SA">SA</SelectItem>
                      <SelectItem value="WA">WA</SelectItem>
                      <SelectItem value="NT">NT</SelectItem>
                      <SelectItem value="TAS">TAS</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex flex-col gap-y-2  max-sm:w-full w-3/12">
                <Label>
                  Postcode<span className="text-red-500">*</span>
                </Label>
                <Input
                  value={form.postcode}
                  type="number"
                  required
                  onChange={(e) =>
                    setForm({ ...form, postcode: parseInt(e.target.value) })
                  }
                  placeholder="2000"
                />
              </div>
            </div>
            <div className="flex flex-row gap-y-2 gap-x-4 pt-6">
              <div className="flex flex-col w-1/4 max-sm:w-full gap-y-6">
                <Label>Is the trustee of the SMSF a company?</Label>
                <Tabs
                  defaultValue={form.isTrusteeCompany ? "yes" : "no"}
                  onValueChange={(e) => {
                    setCompany(e === "yes" ? true : false);
                    setForm({
                      ...form,
                      isTrusteeCompany: e === "yes" ? true : false,
                    });
                  }}
                  className="w-[200px]  max-sm:w-full"
                >
                  <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="no">No</TabsTrigger>
                    <TabsTrigger value="yes">Yes</TabsTrigger>
                  </TabsList>
                </Tabs>
              </div>
            </div>
            {company && (
              <div className="flex max-sm:flex-col flex-row gap-y-2 gap-x-4 pt-6">
                <div className="flex flex-col w-1/2 max-sm:w-full gap-y-2">
                  <Label>
                    Name of Trustee Company
                    <span className="text-red-500">*</span>
                  </Label>
                  <div className="flex flex-row">
                    <Input
                      value={form.trusteeCompanyName}
                      type="text"
                      required
                      placeholder="Company Name"
                      onChange={(e) => {
                        setForm({
                          ...form,
                          trusteeCompanyName: e.target.value,
                        });
                      }}
                    />
                    <Select
                      required
                      value={suffixTemp}
                      onValueChange={(e) => {
                        setSuffixTemp(e);
                        setForm({
                          ...form,
                          trusteeCompanyName: `${companyNameTemp} ${e}`,
                        });
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Suffix" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="PTY LTD">PTY LTD</SelectItem>
                          <SelectItem value="PTY. LTD.">PTY. LTD.</SelectItem>
                          <SelectItem value="PTY. LIMITED">
                            PTY. LIMITED
                          </SelectItem>
                          <SelectItem value="PTY LIMITED">
                            PTY LIMITED
                          </SelectItem>
                          <SelectItem value="PROPRIETARY LTD">
                            PROPRIETARY LTD
                          </SelectItem>
                          <SelectItem value="PROPRIETARY LIMITED">
                            PROPRIETARY LIMITED
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="flex flex-col w-1/2 max-sm:w-full gap-y-2">
                  <Label>
                    ACN of Trustee Company
                    <span className="text-red-500">*</span>
                  </Label>
                  <Input
                    value={form.trusteeCompanyACN}
                    type="text"
                    required
                    placeholder="365 455 652"
                    onChange={(e) => {
                      setForm({ ...form, trusteeCompanyACN: e.target.value });
                    }}
                  />
                </div>
              </div>
            )}

            <div className="flex flex-row gap-y-2 gap-x-4 pt-6">
              <div className="flex flex-col w-1/2 max-sm:w-full gap-y-2">
                <h4 className="text-bold text-2xl text-jd-grey">
                  Member/Trustee Details
                </h4>
                <span className="text-jd-blue font-semibold">
                  Please enter the details of all members/trustees of the SMSF
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-y-2 gap-x-4">
              <div className="flex flex-col gap-y-2 max-sm:w-full w-3/12">
                <Label>How many members/trustees are there?</Label>
                <Select
                  required
                  defaultValue={form.trustees.length.toString()}
                  value={form.trustees.length.toString()}
                  onValueChange={(e) => {
                    setTrustees(parseInt(e));
                    setForm({
                      ...form,
                      trustees: Array(parseInt(e))
                        .fill({
                          firstName: "",
                          middleName: "",
                          lastName: "",
                        })
                        .map((trustee, index) => {
                          if (form.trustees[index]) {
                            return form.trustees[index];
                          }
                          return trustee;
                        }),
                    });
                  }}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {form.isTrusteeCompany ? (
                        <SelectItem value="1">1</SelectItem>
                      ) : (
                        <></>
                      )}
                      <SelectItem value="2">2</SelectItem>
                      <SelectItem value="3">3</SelectItem>
                      <SelectItem value="4">4</SelectItem>
                      <SelectItem value="5">5</SelectItem>

                      <SelectItem value="6">6</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            {form.trustees.map((item, i) => (
              <>
                <div
                  key={`trustee-${i}`}
                  className="flex flex-row max-sm:flex-col gap-y-2 gap-x-4 pt-4"
                >
                  <Label className="max-sm:block hidden">Member {i + 1}</Label>
                  <Separator className="h-[2px] border-jd-blue max-sm:block hidden" />
                  <div className="flex flex-col gap-y-2  max-sm:w-full w-4/12">
                    <Label>
                      First Name<span className="text-red-500">*</span>
                    </Label>
                    <Input
                      value={form.trustees[i].firstName}
                      type="text"
                      required
                      placeholder="First Name"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          trustees: form.trustees.map((trustee, index) => {
                            // if (index === i) {
                            //   return {
                            //     ...trustee,
                            //     firstName: e.target.value,
                            //   };
                            // }
                            return trustee;
                          }),
                        })
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 max-sm:w-full w-4/12">
                    <Label>Middle Name</Label>
                    <Input
                      value={form.trustees[i].middleName}
                      type="text"
                      placeholder="Middle Name"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          trustees: form.trustees.map((trustee, index) => {
                            // if (index === i) {
                            //   return {
                            //     ...trustee,
                            //     middleName: e.target.value,
                            //   };
                            // }
                            return trustee;
                          }),
                        })
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 max-sm:w-full w-4/12">
                    <Label>
                      Last Name<span className="text-red-500">*</span>
                    </Label>
                    <Input
                      value={form.trustees[i].lastName}
                      type="text"
                      required
                      placeholder="Last Name"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          trustees: form.trustees.map((trustee, index) => {
                            // if (index === i) {
                            //   return {
                            //     ...trustee,
                            //     lastName: e.target.value,
                            //   };
                            // }
                            return trustee;
                          }),
                        })
                      }
                    />
                  </div>
                </div>
              </>
            ))}
            <div className="flex flex-col gap-y-2  max-sm:w-full w-3/12">
              <Label>
                Chair Person<span className="text-red-500">*</span>
              </Label>
              <Select
                value={form.chairPerson}
                required
                onValueChange={(e) => setForm({ ...form, chairPerson: e })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a chairperson" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {form.trustees
                      .filter(
                        (e) =>
                          `${e.firstName}${e.middleName}${e.lastName}`.length >
                          4
                      )
                      .map((trustee, index) => {
                        return (
                          <SelectItem
                            key={`trustee-${index}`}
                            value={`${trustee.firstName} ${trustee.middleName} ${trustee.lastName}`}
                          >
                            {trustee.firstName} {trustee.middleName}{" "}
                            {trustee.lastName}
                          </SelectItem>
                        );
                      })}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </>
        )}
        <div className="flex flex-row max-sm:flex-col-reverse max-sm:gap-y-4 justify-between gap-y-2 gap-x-4 pt-6">
          <div className="flex flex-col gap-y-2 max-sm:w-full w-2/12">
            <Button
              disabled={generatingForm}
              onClick={() => {
                window.location.reload();
              }}
              variant="secondary"
            >
              Go Back
            </Button>
          </div>
          <div className="flex flex-col gap-y-2 max-sm:w-full w-4/12">
            {!formSuccess && (
              <Button
                type="submit"
                onClick={(e) => handleForm(e)}
                variant="default"
                disabled={generatingForm}
              >
                Save and Regenerate
              </Button>
            )}
          </div>
        </div>
      </div>

      {/* </form> */}
    </>
  );
};

export default SMSFDeedUpdate;
