import React, { useState } from "react";
import PageContainer from "../../containers/PageContainer";

import { Button } from "../../components/ui/button";
import OrdersTable from "../../components/customers/Orders/OrdersTable";
import { getUserOrders, supabase } from "../../utils/supabase";
import OrdersDataTable from "../../components/customers/Orders/OrdersDataTable";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import SMSFDeedUpdate from "../../components/customers/Forms/SMSFDeedUpdate";
import DeathBenefit from "../../components/customers/Forms/DeathBenefit";
import IndvToCorp from "../../components/customers/Forms/IndvToCorp";

type Props = {};

const Orders = (props: Props) => {
  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any | null>(null);
  const [selectedOrderData, setSelectedOrderData] = useState<any | null>(null);

  const getUsersAuthId = async () => {
    const { data, error } = await supabase.auth.getUser();
    if (data) {
      return data.user?.id;
    } else {
      return null;
    }
  };

  const openEditDialog = (orderId: string) => {
    const order = orders.find((order) => order.id === orderId);
    if (order) {
      console.log(order);
      setSelectedOrderData(order);
      setSelectedOrder(order.document_data);
      setOpenEditForm(true);
    }
  };

  const getOrders = async () => {
    const authId = await getUsersAuthId();
    if (authId) {
      const orders = await getUserOrders(authId);
      if (orders) {
        console.log(orders);
        setOrders(orders);
      }
    }
  };

  React.useEffect(() => {
    getOrders().then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <PageContainer pageTitle="Orders">
      <div>
        {/* <p>{process.env.REACT_APP_BASE_URL}</p> */}
        <OrdersDataTable data={orders} onEditOrderClick={openEditDialog} />
      </div>
      <Dialog
        open={openEditForm}
        onOpenChange={() => setOpenEditForm(!openEditForm)}
        modal={true}
      >
        <DialogContent
          className={"lg:max-w-screen-lg overflow-y-scroll max-h-screen"}
        >
          {selectedOrder && selectedOrderData?.product_id === 2 && (
            <SMSFDeedUpdate
              data={selectedOrder}
              order_id={selectedOrderData.id}
            />
          )}
          {selectedOrder && selectedOrderData?.product_id === 1 && (
            <DeathBenefit
              data={selectedOrder}
              order_id={selectedOrderData.id}
            />
          )}
          {selectedOrder && selectedOrderData?.product_id === 3 && (
            <IndvToCorp data={selectedOrder} order_id={selectedOrderData.id} />
          )}
        </DialogContent>
      </Dialog>
    </PageContainer>
  );
};

export default Orders;
