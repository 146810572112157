import React, { useEffect, useState } from "react";
import { invokeFunction } from "../../../utils/supabase";
import { useUser } from "../../../context/User.context";
import { Separator } from "../../ui/seperator";
import Visa from "../../../assets/images/public/card-icons/1.png";
import MasterCard from "../../../assets/images/public/card-icons/2.png";
import Amex from "../../../assets/images/public/card-icons/22.png";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import { DialogContent, Dialog } from "../../ui/dialog";
import { Input } from "../../ui/input";
import moment from "moment";
import {
  Select,
  SelectGroup,
  SelectItem,
  SelectContent,
  SelectTrigger,
  SelectValue,
  SelectLabel,
} from "../../ui/select";
import { Blocks } from "react-loader-spinner";

type Props = {};

interface PaymentMethod {
  id: string;
  brand: string;
  last4: string;
  expMonth: number;
  expYear: number;
}

const BillingForm = (props: Props) => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(
    null
  );
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openCardDelete, setOpenCardDelete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const { email } = useUser();

  const getCustomerPaymentMethods = async () => {
    try {
      const pm = await invokeFunction("stripeGetCustomerPaymentMethods", {
        email: email,
      });
      try {
        console.log(pm.payment_methods[0].card);
        const card = pm.payment_methods[0].card;
        setPaymentMethod({
          id: pm.payment_methods[0].id,
          brand: card.brand,
          last4: card.last4,
          expMonth: card.exp_month,
          expYear: card.exp_year,
        });
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCustomerPaymentMethods().then(() => setLoading(false));
  }, []);

  return (
    <>
      {loading ? (
        <Blocks
          height={100}
          width={100}
          color="#4fa94d"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          visible={true}
        />
      ) : !paymentMethod ? (
        <div>
          Once you have made an order you will be able to update your billing
          information
        </div>
      ) : (
        <>
          <div className="flex flex-col space-y-6">
            <div className="flex flex-col">
              <h3 className="font-bold">Saved Payment Method</h3>
              <span className="text-sm text-jd-grey">
                Edit your payment details{" "}
              </span>
            </div>
            <Separator className="my-4" />
            <div className="flex flex-col">
              <span className="text-sm text-jd-grey">Card Number</span>
              <span className="text-lg font-bold flex flex-row items-center">
                <CardIcon brand="visa" /> **** **** **** {paymentMethod.last4}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-sm text-jd-grey">Expiry Date</span>
              <span className="text-lg font-bold flex flex-row items-center">
                {paymentMethod.expMonth}/{paymentMethod.expYear}
              </span>
            </div>
            <div className="flex flex-row gap-x-4">
              <Button onClick={() => setOpenDialog(true)}>
                Change Card Details
              </Button>
              <Button
                variant="destructive"
                onClick={() => setOpenCardDelete(true)}
              >
                Delete
              </Button>
            </div>
          </div>
          <Dialog
            open={openCardDelete}
            onOpenChange={() => setOpenCardDelete(!openCardDelete)}
          >
            <ConfirmDeleteDialog />
          </Dialog>
          <Dialog
            open={openDialog}
            onOpenChange={() => setOpenDialog(!openDialog)}
          >
            <EditCardDialog />
          </Dialog>
        </>
      )}
    </>
  );
};

const EditCardDialog = () => {
  const [cardNumber, setCardNumber] = useState<string>("");
  const [expiryMonth, setExpiryMonth] = useState<number>(moment().month() + 1);
  const [expiryYear, setExpiryYear] = useState<number>(moment().year());
  const [cvc, setCvc] = useState<string>("");

  const handleExpiryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 4) {
      // setExpiry(value);
    }
  };

  return (
    <DialogContent>
      <div className="flex flex-col w-full gap-y-6 px-4 py-6">
        <div className="flex flex-row  gap-x-4">
          <div className="flex flex-col w-full">
            <Label className="text-xl">Change Card Details</Label>
          </div>
        </div>
        <div className="flex flex-row gap-y-2 gap-x-4">
          <div className="flex flex-col gap-y-2 w-full">
            <Label className="text-jd-grey font-normal">Card Number</Label>
            <Input type="text" placeholder="Card Number" />
          </div>
        </div>

        <div className="flex flex-row gap-y-2 gap-x-4">
          <div className="flex flex-col gap-y-2 w-full">
            <div className="flex flex-row gap-x-4">
              <div className="flex flex-col gap-y-2 w-full">
                <Label>Expiry</Label>
                <div className="flex flex-row gap-x-4">
                  <div className="w-5/12">
                    <Select
                      onValueChange={(e) => {
                        setExpiryMonth(parseInt(e));
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Month" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {Array.from(Array(12).keys()).map((month) => (
                            <SelectItem key={month} value={`${month + 1}`}>
                              {month + 1}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="w-7/12">
                    <Select
                      onValueChange={(e) => {
                        setExpiryMonth(parseInt(e));
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Year" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {/* Map out next 10 years */}
                          {Array.from(Array(10).keys()).map((year) => (
                            <SelectItem
                              key={year}
                              value={`${moment().year() + year}`}
                            >
                              {moment().year() + year}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-2 w-full">
                <Label>CCV</Label>
                <div className="flex flex-row gap-x-4">
                  <div className="w-full">
                    <Input type="text" placeholder="CCV" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-y-2 gap-x-4">
          <div className="flex flex-col gap-y-2 w-full">
            <Button variant="default">Update Card</Button>
          </div>
        </div>
      </div>
    </DialogContent>
  );
};

const ConfirmDeleteDialog = () => {
  return (
    <DialogContent>
      <div className="flex flex-col w-full gap-y-6 px-4 py-6">
        <div className="flex flex-row  gap-x-4">
          <div className="flex flex-col w-full">
            <Label className="text-xl">Confirm Delete Card</Label>
          </div>
        </div>
        <div className="flex flex-row gap-y-2 gap-x-4">
          <div className="flex flex-col gap-y-2 w-full">
            <Label className="text-jd-grey font-normal">
              Are you sure you want to delete this card?
            </Label>
          </div>
        </div>

        <div className="flex flex-row gap-y-2 gap-x-4">
          <div className="flex flex-col gap-y-2 w-full">
            <Button variant="destructive">Delete Card</Button>
          </div>
        </div>
      </div>
    </DialogContent>
  );
};

const CardIcon = ({ brand }: { brand: string }) => {
  const getCardIcon = () => {
    switch (brand) {
      case "visa":
        return Visa;
      case "mastercard":
        return MasterCard;
      case "amex":
        return Amex;
      case "discover":
        return MasterCard;
      case "diners":
        return MasterCard;
      case "jcb":
        return MasterCard;
      default:
        return MasterCard;
    }
  };
  return (
    <div className="w-16">
      <img src={getCardIcon()} alt="card-icon" />
    </div>
  );
};

export default BillingForm;
