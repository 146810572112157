import React, { useState } from "react";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import { Button } from "../../components/ui/button";
import axios from "axios";
import { invokeFunction } from "../../utils/supabase";
import { Oval } from "react-loader-spinner";
type Props = {};

interface EmailContent {
  email: string;
  name: string;
  message: string;
}

const Contact = (props: Props) => {
  const [emailContent, setEmailContent] = useState<EmailContent>({
    email: "",
    name: "",
    message: "",
  });
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  const sendEmail = async () => {
    let d = await invokeFunction("sendContactEmail", {
      email: emailContent.email,
      name: emailContent.name,
      message: emailContent.message,
    });

    return d;
  };

  const handleEmailSend = async () => {
    try {
      setSending(true);
      let d = await sendEmail();
      console.log(d);
      if (d.statusText === "Accepted") {
        setMessageSent(true);
        setSending(false);
        setTimeout(() => {
          setMessageSent(false);
        }, 3000);
      }
    } catch (e) {
      setSending(false);
      alert("Error sending email, please try again later");
    }
  };

  return (
    <div className="container my-24 mx-auto md:px-6">
      <section className="mb-32">
        <div className="flex flex-wrap">
          <div className="mb-10 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:px-6">
            <h2 className="mb-6 text-3xl font-bold">Contact us</h2>
            <div className="text-jd-gray">
              <p className="mb-6 text-neutral-500 ">
                Fill out the contact form and we will endevour to get back to
                you as soon as possible
              </p>

              <a href="mailto:info@justdocs.com.au">
                <p className="mb-2 underline">info@justdocs.com.au</p>
              </a>
            </div>
          </div>
          <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:px-6">
            <div className="relative mb-6" data-te-input-wrapper-init>
              <Label>Name</Label>
              <Input
                type="text"
                placeholder="Name"
                onChange={(e) => {
                  setEmailContent({ ...emailContent, name: e.target.value });
                }}
              />
            </div>
            <div className="relative mb-6" data-te-input-wrapper-init>
              <Label>Email</Label>
              <Input
                type="email"
                placeholder="Email"
                onChange={(e) => {
                  setEmailContent({ ...emailContent, email: e.target.value });
                }}
              />
            </div>
            <div className="relative mb-6" data-te-input-wrapper-init>
              <Label>Message</Label>
              <Textarea
                rows={5}
                placeholder="Enter your Message Here"
                onChange={(e) => {
                  setEmailContent({
                    ...emailContent,
                    message: e.target.value,
                  });
                }}
              />
            </div>
            {messageSent && (
              <div className="bg-green-500 text-white p-3 mb-3 rounded-md">
                <p className="">Message Sent</p>
              </div>
            )}
            <Button disabled={sending} onClick={() => handleEmailSend()}>
              {sending === true ? (
                <Oval width="20" height="20" />
              ) : (
                "Send Message"
              )}
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
