import React, { useEffect } from "react";
import { supabase, getUser } from "../utils/supabase";
import { useNavigate } from "react-router-dom";

interface State {
  auth_id: string;
  email: string;
  role: string | null | undefined;
  admin: boolean | null | undefined;
  storeUserDetails: (user: any) => void;
}

const initialState: State = {
  auth_id: "",
  email: "",
  role: "",
  admin: null,
  storeUserDetails: (user: any) => {},
};

// initialize context
export const UserContext = React.createContext(initialState);
UserContext.displayName = "UserContext";

// useDesigner to use context
export const useUser = () => React.useContext(UserContext);

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [auth_id, setAuth_id] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [role, setRole] = React.useState<string | null | undefined>(null);
  const [admin, setAdmin] = React.useState<boolean | null | undefined>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const storeUserDetails = async (user: any) => {
    console.log(user);
    console.log("Storing user details");
    setAuth_id(user.auth_id);
    setEmail(user.email);
    setRole(user.role);
    setAdmin(user.role === "admin");
  };

  const checkAuthStatus = async () => {
    const user = await getUser();
    if (!user) return;
    await storeUserDetails(user[0]);
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  return (
    <UserContext.Provider
      value={{ auth_id, email, role, admin, storeUserDetails }}
    >
      {children}
    </UserContext.Provider>
  );
};
