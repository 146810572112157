import React from "react";

type Props = {
  children: React.ReactNode;
  pageTitle?: string;
};

const PageContainer = ({ children, pageTitle }: Props) => {
  return (
    <div className="px-16 py-10 max-sm:px-6 max-sm:py-4">
      <div className="flex flex-row w-full">
        <h2 className="text-[32px] font-bold text-jd-grey">{pageTitle}</h2>
      </div>
      {children}
    </div>
  );
};

export default PageContainer;
