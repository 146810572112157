import React, { useEffect, useState } from "react";
import PageContainer from "../../containers/PageContainer";
import { Button } from "../../components/ui/button";
import { useNavigate } from "react-router-dom";
import {
  supabase,
  invokeFunction,
  processDocument,
} from "../../utils/supabase";
import { useOrder } from "../../context/Order.context";
import { useUser } from "../../context/User.context";
import { Blocks } from "react-loader-spinner";

type Props = {};

const PaymentConfirmation = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(true);

  // Get URL Params
  const params = new URLSearchParams(window.location.search);
  const paymentIntent = params.get("payment_intent");
  const paymentClientSecret = params.get("payment_intent_client_secret");

  const { auth_id } = useUser();
  const order_id = localStorage.getItem("order_number");

  // Confirm payment intent

  const confirmOrderAndCreateDocument = async () => {
    try {
      if (!paymentIntent || !paymentClientSecret) {
        alert(
          "There was an issue please try again. If this continues please contact support."
        );
        return;
      }

      const receiptRequest = await invokeFunction(
        "stripeGetInvoiceFromPaymentIntent",
        {
          payment_intent: paymentIntent,
        }
      );
      const receiptUrl = receiptRequest.invoice_url;
      const { data, error } = await supabase
        .from("document_orders")
        .update({
          status: "Paid",
          stripe_payment_id: paymentIntent,
          invoice_url: receiptUrl ? receiptUrl : null,
        })
        .eq("id", order_id)
        .select();
      console.log(data, error);

      if (data && data[0].product_id === 1) {
        const createDoc = await invokeFunction("createDeathBenefitAgreement", {
          form: data[0].document_data,
          auth_id: auth_id,
          order_id: order_id,
        });

        console.log(createDoc);
      }

      if (data && data[0].product_id === 2) {
        const createDoc = await invokeFunction("createTrustDeedPDF", {
          form: data[0].document_data,
          auth_id: auth_id,
          order_id: order_id,
        });

        console.log(createDoc);
      }

      if (data && data[0].product_id === 3) {
        const createDoc = await processDocument("indv-to-corp", {
          form: data[0].document_data,
          auth_id: auth_id,
          order_id: order_id,
        });

        console.log(createDoc);
      }

      localStorage.removeItem("order_number");

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    console.log(order_id, auth_id);
    if (order_id && auth_id) {
      confirmOrderAndCreateDocument();
    }
  }, [auth_id, order_id]);

  return (
    <PageContainer>
      {loading === false && (
        <div className="w-full min-h-screen gap-y-4 flex flex-col align-middle items-center mt-20">
          <h2 className="text-4xl font-semibold text-jd-grey">
            Thank you for your purchase
          </h2>
          <p className="text-jd-blue">
            Your document has been processed successfully.
          </p>
          <p className="text-jd-blue">
            Your documents are available under the order section.
          </p>
          <Button
            onClick={() => navigate("/customer/orders", { replace: true })}
            className="w-1/4"
          >
            Go to Orders
          </Button>
        </div>
      )}
      {loading === true && (
        <div className="w-full min-h-screen gap-y-4 flex flex-col align-middle items-center mt-20">
          <h2 className="text-4xl font-semibold text-jd-grey">
            We are processing your document. Please don't navigate away from
            this page yet
          </h2>
          <Blocks
            height={200}
            width={200}
            color="#4fa94d"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            visible={true}
          />
        </div>
      )}
    </PageContainer>
  );
};

export default PaymentConfirmation;
