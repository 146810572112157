import React, { useEffect, useState } from "react";
import CustomerTable from "../../components/admin/Customers/CustomerTable";
import PageContainer from "../../containers/PageContainer";
import CustomerDataTable from "../../components/admin/Customers/CustomerDataTable";
import { getCustomers } from "../../utils/supabase";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

type Props = {
  customerData?: any;
};

const Customers = (props: Props) => {
  const [customers, setCustomers] = useState<any[]>([]);

  useEffect(() => {
    getCustomers().then((customers) => {
      if (customers) {
        const cus = customers.map((c) => {
          return {
            ...c,
            role: c.role.toUpperCase(),
          };
        });
        setCustomers(customers);
      }
    });
  }, []);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(customers);
    const wb = { Sheets: { Customers: ws }, SheetNames: ["Customers"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = `JustDocs_Customers${fileExtension}`;
    FileSaver.saveAs(data, fileName);
  };

  return (
    <PageContainer pageTitle="Clients">
      <div className="flex flex-row">
        {/* <CustomerTable /> */}
        <CustomerDataTable data={customers} exportToFile={exportToCSV} />
      </div>
    </PageContainer>
  );
};

export default Customers;
