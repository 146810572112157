import React, { useEffect, useState } from "react";
import { Button } from "../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import moment from "moment";
import { getProducts } from "../../utils/supabase";
import { Label } from "../ui/label";
import { useNavigate } from "react-router-dom";

type Props = {
  data?: Document[];
  header: string;
};

interface Document {
  id: string;
  name: string;
  price: number;
}

const DocumentsTable = (props: Props) => {
  const [documents, setDocuments] = useState<Document[] | any[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.data) setDocuments(props.data);
    else
      getProducts().then((res) => {
        if (res) setDocuments(res.filter((doc: any) => doc.active === true));
      });
  }, []);

  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex flex-row">
        <Label className="text-2xl font-bold text-jd-grey">
          {props.header}
        </Label>
      </div>

      <Table className="max-sm:text-xs">
        <TableHeader>
          <TableRow className="p-0">
            <TableHead className="w-[30%] max-sm:w-[50%]">Name</TableHead>
            <TableHead className="w-[20%] max-sm:w-[30%]">Price</TableHead>
            <TableHead className="w-[50%] max-sm:w-[40%]"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {documents.map((document) => (
            <TableRow className="" key={document.id}>
              <TableCell className="font-medium p-0">{document.name}</TableCell>
              <TableCell className="font-medium">
                <span className="text-lg max-sm:text-xs">
                  ${document.price.toFixed(2)}
                </span>{" "}
                inc GST
              </TableCell>
              <TableCell>
                <div className="flex flex-row max-sm:flex-col max-sm: gap-y-4 w-full gap-x-2 justify-end">
                  <Button
                    variant="secondary"
                    className="border-2 border-jd-grey max-sm:px-2 max-sm:py-2"
                    onClick={() => {
                      navigate(
                        `/docs/${
                          document.id === 1 ? "death-benefit" : "trust-deed"
                        }`
                      );
                    }}
                  >
                    Learn More
                  </Button>
                  <Button
                    className="max-sm:text-sm"
                    onClick={() => {
                      navigate(
                        `/docs/${
                          document.id === 1 ? "death-benefit" : "trust-deed"
                        }`
                      );
                    }}
                  >
                    Buy Now
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default DocumentsTable;
